import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { InputCalendarIcon, ProfilePhotoIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { ImageUpload } from 'components/app/image-upload'
import { AppLayout } from 'components/app/layout'
import { InputNumber, LabelInput } from 'components/inputs/input'
import { RadioInput } from 'components/inputs/radio'
import { Language } from 'constants/constants'
import { useAppDispatch } from 'hooks'
import userService from 'services/user-service'
import { saveUser } from 'slices/user'
import { getAppLang, getTKey } from 'utils/language'

export const EditUser = () => {
	const dispatch = useAppDispatch()
	const { t } = useTranslation()
	const tKey = getTKey('editUser')

	const [user, setUser] = useState<User>()
	const [image, setImage] = useState<File>()
	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object({
		fname: yup.string().required(t('auth.errors.firstname')),
		lname: yup.string().required(t('auth.errors.lastname')),
		gender: yup.string().required(t('auth.errors.gender')),
		email: yup.string().required(t('auth.errors.email')).email(t('auth.errors.validEmail')),
		pID: yup.string().notRequired(),
		phone: yup.string().required(t('auth.errors.phonenumber')).max(13, t('auth.errors.maxPhone')),
		insuranceNumber: yup
			.string()
			.test('insuranceNumber-test', t('auth.errors.validInsuranceNumber'), function (value) {
				if (value && !/^80756\d{15}$/.test(value)) {
					return false
				}
				return true
			}),
		birthDate: yup.string().notRequired(),
		streetNumber: yup.string().required(t('auth.errors.streetNumber')),
		country: yup.string().required(t('auth.errors.country')),
		address: yup.string().required(t('auth.errors.address')),
		city: yup.string().required(t('auth.errors.city')),
		zip: yup.string().required(t('auth.errors.zip'))
	})

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		control
	} = useForm<User>({
		resolver: yupResolver(schema as any),
		defaultValues: { country: getAppLang() === Language.EN ? 'Switzerland' : 'Schweiz' },
		mode: 'all'
	})

	useEffect(() => {
		userService.getUserInfo().then(res => {
			reset({
				...res,
				birthDate: DateTime.fromMillis(Number(res.birthDate)).toISODate() as any
			})
			setUser(res)
		})
	}, [])

	const onSubmit = (data: User) => {
		const { companyId, insuranceNumber, pID, favorites, birthDate, ...rest } = data as any
		let payload = {
			...rest
		}

		if (data.birthDate) {
			payload = {
				...rest,
				birthDate: DateTime.fromJSDate(data.birthDate as any).toMillis()
			}
		}

		setIsLoading(true)
		const formData = new FormData()
		for (const key in payload) {
			if (Object.prototype.hasOwnProperty.call(payload, key)) {
				const value = (payload as { [key: string]: any })[key]
				formData.append(key, value)
			}
		}
		if (image) {
			formData.append('file', image)
		}

		userService
			.updateUserInfo(formData)
			.then(() => {
				toast.success(t(tKey('toast.updateSuccess')))
				userService.getUserInfo().then(res => dispatch(saveUser(res)))
			})
			.catch(() => toast.error(t(tKey('toast.updateError'))))
			.finally(() => setIsLoading(false))
	}

	return (
		<AppLayout renderDashboardHeader={true}>
			<form onSubmit={handleSubmit(onSubmit)} className="py-8 px-10 xl:px-32 2xl:px-52">
				<div className="flex flex-col gap-y-3.5">
					<div className="lg:pb-8 pb-4 lg:border-b lg:border-[#D3E3F1]">
						<h1 className="font-domine max-lg:text-center text-sm text-primary lg:text-[22px] font-bold">
							{t(tKey('headings.personalInfo'))}
						</h1>
					</div>
					<ImageUpload
						image={user?.profile_url === 'crm' ? ProfilePhotoIcon : user?.profile_url}
						onUpload={(file: File) => setImage(file)}
						name="user-profile"
					/>
				</div>

				<div className="flex flex-col gap-y-5 mt-3.5">
					<h2 className="text-primary font-domine font-bold text-sm lg:text-lg">
						{t(tKey('headings.basicInfo'))}
					</h2>

					<Controller
						control={control}
						name="gender"
						render={({ field: { onChange, value } }) => (
							<div className="flex flex-col">
								<div className="flex flex-row gap-x-3">
									<RadioInput
										labelText={t(tKey('labels.male'))}
										onChange={onChange}
										name="gender"
										register={register}
										value="male"
										checked={value === 'male'}
									/>
									<RadioInput
										labelText={t(tKey('labels.female'))}
										onChange={onChange}
										register={register}
										name="gender"
										value="female"
										checked={value === 'female'}
									/>
								</div>
								{errors?.gender && (
									<p className="text-xs text-red-500 mt-1">{errors.gender.message}</p>
								)}
							</div>
						)}
					/>
					<div className="flex flex-col gap-y-5 lg:flex-row gap-x-5">
						<LabelInput
							register={register}
							errors={errors}
							name="fname"
							labelText={t(tKey('labels.firstName'))}
						/>
						<LabelInput
							register={register}
							errors={errors}
							name="lname"
							labelText={t(tKey('labels.lastName'))}
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-y-5 lg:grid-flow-col gap-x-5">
						<LabelInput register={register} errors={errors} name="email" labelText="Email" />
						<div className="relative z-20 grow flex items-center">
							<LabelInput
								name="birthDate"
								register={register}
								errors={errors}
								labelText={t(tKey('labels.dob'))}
								type="date"
							/>
							<div
								className={clsx('absolute -z-20 right-3', {
									'-translate-y-2.5': errors?.birthDate
								})}>
								<img src={InputCalendarIcon} />
							</div>
						</div>
					</div>

					<Controller
						control={control}
						name={'phone'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<PhoneInput
									numberInputProps={{
										className:
											'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
									}}
									placeholder="Enter phone number"
									defaultCountry="CH"
									value={value}
									error={error}
									onChange={onChange}
								/>
								{errors?.phone && (
									<p className="text-xs text-red-500 mt-1">{errors.phone.message as string}</p>
								)}
							</div>
						)}
					/>
				</div>
				<div className="flex flex-col gap-y-6 mt-5">
					<h2 className="text-primary font-domine font-bold text-base lg:text-lg">
						{t(tKey('headings.locationInfo'))}
					</h2>
					<div className="flex flex-col gap-y-5">
						<div className="flex flex-col gap-y-5 lg:flex-row gap-x-5">
							<LabelInput
								register={register}
								errors={errors}
								labelText={t(tKey('labels.location'))}
								name="address"
							/>
							<Controller
								control={control}
								name="streetNumber"
								render={({ field: { onChange, value } }) => (
									<InputNumber
										onChange={onChange}
										value={value}
										register={register}
										errors={errors}
										labelText={t(tKey('labels.streetNo'))}
										name="streetNumber"
									/>
								)}
							/>
							<Controller
								control={control}
								name="zip"
								render={({ field: { onChange, value } }) => (
									<InputNumber
										onChange={onChange}
										value={value}
										register={register}
										errors={errors}
										labelText={t(tKey('labels.zipCode'))}
										name="zip"
									/>
								)}
							/>
						</div>
						<div className="flex flex-col lg:flex-row gap-5">
							<LabelInput
								register={register}
								errors={errors}
								labelText={t(tKey('labels.city'))}
								name="city"
							/>
							<LabelInput
								register={register}
								errors={errors}
								labelText={t(tKey('labels.country'))}
								name="country"
							/>
						</div>
					</div>
					<Button
						disabled={isLoading}
						className="lg:w-fit lg:ml-auto lg:font-bold max-lg:font-bold">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t('auth.login.labels.pleaseWait')}
								</span>
							</div>
						) : (
							<span>{t(tKey('labels.save'))}</span>
						)}
					</Button>
				</div>
			</form>
		</AppLayout>
	)
}
