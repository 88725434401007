import { ArrowPathIcon } from '@heroicons/react/20/solid'
import { ArrowDownTrayIcon, CheckIcon, MapPinIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { pdf } from '@react-pdf/renderer'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactQRCode from 'react-qr-code'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { DateTime, Duration } from 'luxon'
import QRCode from 'qrcode'

import {
	DangerIcon,
	ExclamationMarkIcon,
	InputCalendarIcon,
	OutlineGlobeIcon,
	OutlineMailIcon,
	OutlinePhoneIcon
} from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { MobileNavigation } from 'components/app/mobile-navigation'
import { Modal } from 'components/app/modal'
import { Pagination } from 'components/app/pagination'
import { Table } from 'components/app/table'
import { Tabs } from 'components/app/tabs'
import { TimePicker } from 'components/app/time-picker'
import { PastAppointmentCard, UpcomingAppointmentCard } from 'components/cards/appointment-card'
import { AppointmentStatus, mappedStatuses } from 'constants/constants'
import useIsMobile from 'hooks/useIsMobile'
import { ApppointmentDocument } from 'pages/user/appointments/pdf'
import appointmentService from 'services/appointment-service'
import service from 'services/service'
import userService from 'services/user-service'
import { isUpcomingAppointment, isWithinUpdateTime } from 'utils/appointment'
import {
	appendDateTime,
	getDate,
	getDay,
	getTime,
	getTimeRange,
	withinCancellationTime
} from 'utils/date'
import { getAppLang, getTKey } from 'utils/language'
import { getServiceDisplayPrice } from 'utils/price'
import addJobToFirebase from '../../../firebase/utility'

enum AppointmentTabs {
	UPCOMING = 'upcoming',
	PAST = 'past'
}

export const UserAppointments = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const [searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('appointment')
	const appLanguage = getAppLang()

	const [user, setUser] = useState<User>()
	const [selectedTimeSlot, setSelectedTimeslot] = useState('')
	const [selectedDate, setSelectedDate] = useState('')
	const [isLoading, setIsLoading] = useState(true)
	const [deletePrompt, setDeletePrompt] = useState<{ id: string; message: string } | undefined>()
	const [updatedAppointment, setUpdatedAppointment] = useState<Appointment | undefined>()
	const [isUpdating, setIsUpdating] = useState(false)
	const [appointments, setAppointments] = useState<Appointment[]>([])
	const [selectedAppointment, setSelectedAppointment] = useState<Appointment>()
	const [showModal, setShowModal] = useState(false)
	const [forceRefresh, setForceRefresh] = useState(false)
	const [serviceSchedule, setServiceSchedule] = useState<Schedule[]>()

	useEffect(() => {
		userService.getUserInfo().then(res => setUser(res))
	}, [])

	useEffect(() => {
		setSearchParams({ appointment: AppointmentTabs.UPCOMING }, { replace: true })
	}, [])

	useEffect(() => {
		if (user?._id) {
			appointmentService
				.getAppointmentByUserId()
				.then(response => setAppointments(response))
				.finally(() => setIsLoading(false))
		}
	}, [user, forceRefresh])

	useEffect(() => {
		if (updatedAppointment) {
			service.getServiceSchedule(updatedAppointment.id_service).then(res => setServiceSchedule(res))
		}
	}, [updatedAppointment])

	const timeSlots = useMemo(() => {
		const mergedTime: Record<number, string[]> = {}
		for (const schedule of serviceSchedule ?? []) {
			if (schedule.type === 'Closed') continue

			const timeRange = getTimeRange(
				DateTime.fromObject(schedule.start).toMillis(),
				DateTime.fromObject(schedule.end).toMillis(),
				DateTime.fromObject(schedule.pause_start).toMillis(),
				DateTime.fromObject(schedule.pause_end).toMillis(),
				false,
				updatedAppointment?.service?.duration as string,
				schedule.day,
				Number(updatedAppointment?.service?.min_booking) / 60
			)

			if (timeRange) {
				Object.entries(timeRange).forEach(([key, value]) => {
					const day = parseInt(key)
					if (!mergedTime[day]) {
						mergedTime[day] = []
					}
					mergedTime[day].push(...value)
				})
			}
		}

		return mergedTime
	}, [serviceSchedule])

	const todayTimeSlots = useMemo(() => {
		for (const schedule of serviceSchedule ?? []) {
			if (schedule.type === 'Closed') return
			return getTimeRange(
				DateTime.fromObject(schedule.start).toMillis(),
				DateTime.fromObject(schedule.end).toMillis(),
				DateTime.fromObject(schedule.pause_start).toMillis(),
				DateTime.fromObject(schedule.pause_end).toMillis(),
				true,
				updatedAppointment?.service?.duration as string,
				DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday,
				Number(updatedAppointment?.service?.min_booking) / 60
			)
		}
	}, [serviceSchedule])

	const onCancelAppointment = (id: string) => {
		appointmentService
			.cancelAppointment(id, { status: 'canceled' })
			.then(res => {
				addJobToFirebase(res.data)
				toast.success(t(tKey('create.toast.appointmentCancel')))
				setTimeout(() => {
					window.location.reload()
				}, 3000)
			})
			.catch(error => {
				if (error?.response?.data?.message) {
					toast.error(error?.response?.data?.message)
				}
			})
			.finally(() => setDeletePrompt(undefined))
	}

	const emailInvoice = (appointment: Appointment) => {
		const sendPdf = async () => {
			const qrCodeURL = await QRCode.toDataURL(
				`https://pro.stylingcorner.ch/appointment-details/${appointment._id}`,
				{
					margin: 0,
					width: 80
				}
			)
			const blob = await pdf(
				<ApppointmentDocument qrCodeURL={qrCodeURL} appointment={appointment} />
			).toBlob()
			const formData = new FormData()
			const fileName = `${appointment.id_client.fname + '_' + DateTime.fromMillis(appointment.from).toFormat('dd.LL.yyyy')}_appointment.pdf`
			formData.append('file', blob, `${fileName}.pdf`)
			formData.append('id_client', appointment.id_client._id)
			formData.append('id_company', appointment.id_company._id)
			await appointmentService.sendEmail(appointment._id, formData)
			toast.success(t(tKey('create.toast.updateSuccess')))
			setForceRefresh(prev => !prev)
			setSelectedDate('')
			setSelectedTimeslot('')
			setUpdatedAppointment(undefined)
		}
		sendPdf()
	}

	const onUpdateAppointment = () => {
		setIsUpdating(true)
		const startTime = appendDateTime(new Date(selectedDate) as any, selectedTimeSlot)
		const endTime = appendDateTime(
			new Date(selectedDate) as any,
			DateTime.fromFormat(selectedTimeSlot, 'HH:mm')
				.plus({
					minutes: Number(updatedAppointment?.service?.duration ?? 0)
				})
				.toFormat('HH:mm')
		)

		appointmentService
			.updateAppointment(updatedAppointment?._id as string, startTime, endTime)
			.then(res => {
				addJobToFirebase(res.data)
				emailInvoice(res.data)
			})
			.catch(err =>
				toast.error(err?.response?.data?.message ?? t(tKey('create.toast.updateError')))
			)
			.finally(() => setIsUpdating(false))
	}

	const generateInvoice = (appointment: Appointment) => {
		const generatePdf = async () => {
			const qrCodeURL = await QRCode.toDataURL(
				`https://pro.stylingcorner.ch/appointment-details/${appointment._id}`,
				{
					margin: 0,
					width: 80
				}
			)
			const blob = await pdf(
				<ApppointmentDocument qrCodeURL={qrCodeURL} appointment={appointment} />
			).toBlob()
			const url = URL.createObjectURL(blob)

			const fileName = `${appointment.id_client.fname + '_' + DateTime.fromMillis(appointment.from).toFormat('dd.LL.yyyy')}_appointment.pdf`
			const link = document.createElement('a')
			link.href = url
			link.download = `${fileName}.pdf`
			link.target = '_blank'

			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
		}
		generatePdf()
	}

	const renderAppointmentsTBody = (appointmentsData: Appointment[]) => {
		const navigate = useNavigate()

		return (
			<tbody>
				{appointmentsData.map(appointment => {
					return (
						<tr
							onClick={() => {
								if (searchParams.get('appointment') === AppointmentTabs.PAST)
									return navigate(`/user/appointments/details/${appointment._id}`)
								setShowModal(true)
								setSelectedAppointment(appointment)
							}}
							className="card-shadow bg-white rounded-lg cursor-pointer"
							key={appointment._id}>
							<td className="tw-table-td rounded-l-lg border-l border-[#26B6A64D]">
								<img
									src={appointment.id_company.logo_url}
									className="rounded w-[83px] h-[70px] object-cover"
								/>
							</td>

							<td className="tw-table-td text-left">
								<div className="flex flex-col">
									<span className="text-base text-ellipsis line-clamp-1">
										{appointment.company_name}
									</span>
									<span className="text-secondary font-normal">{`${appointment.id_company.address} ${appointment.id_company.address_no}, ${appointment.id_company.zip} ${appointment.id_company.city}`}</span>
								</div>
							</td>
							{searchParams.get('appointment') === AppointmentTabs.PAST && (
								<td className="tw-table-td text-sm text-secondary ">
									{appointment?.appointmentNumber.toString().padStart(7, '0')}
								</td>
							)}
							<td className="tw-table-td whitespace-nowrap">{appointment.service_name}</td>
							<td className="tw-table-td">
								<div className="flex flex-col">
									<span>{getDate(appointment.from)}</span>
									<span>{getDay(appointment.from)}</span>
								</div>
							</td>
							<td className="tw-table-td whitespace-nowrap">
								{getTime(appointment.from)} - {getTime(appointment.to)}
							</td>
							<td className="tw-table-td">{getServiceDisplayPrice(appointment)}</td>
							<td className="p-3 border-t border-b border-[#26B6A64D]">
								<div
									className={clsx(
										'flex items-center whitespace-nowrap mx-auto w-fit justify-between rounded-full py-1 px-4 text-[#FFF]',
										{
											'bg-[#2EEBE2]': appointment.status === AppointmentStatus.CONFIRMED,
											'bg-[#26B6A6]': appointment.status === AppointmentStatus.COMPLETED,
											'bg-[#F84C6B]': appointment.status === AppointmentStatus.CANCELLED,
											'bg-blue-500': appointment.status === AppointmentStatus.CHECKIN,
											'bg-[#F5B715]': appointment.status === AppointmentStatus.PENDING,
											'bg-orange-500': appointment.status === AppointmentStatus.PENDENT
										}
									)}>
									<span>{mappedStatuses[appointment.status as AppointmentStatus]}</span>
									<CheckIcon className="w-4 h-4 shrink-0" />
								</div>
							</td>
							<td className="tw-table-td">
								<ArrowDownTrayIcon
									onClick={event => {
										event.stopPropagation()
										generateInvoice(appointment)
									}}
									className="size-5 cursor-pointer"
								/>
							</td>
							{isUpcomingAppointment(appointment.from, appointment.status) && (
								<td
									className={clsx('tw-table-td', {
										'pr-0': isWithinUpdateTime(appointment.from, appointment.status)
									})}>
									<div className="px-4 py-[11px]">
										<div
											onClick={event => {
												event.stopPropagation()
												if (
													withinCancellationTime(
														Duration.fromObject({
															minutes: Number(appointment?.service.min_cancel)
														}),
														DateTime.fromMillis(appointment?.from)
													)
												) {
													setDeletePrompt({
														id: appointment._id,
														message: t(tKey('extraPayment'))
													})
												} else {
													setDeletePrompt({
														id: appointment._id,
														message: t(tKey('create.toast.cancelConfirm'))
													})
												}
											}}
											className="flex gap-x-1 cursor-pointer">
											<XMarkIcon className="h-4 w-4 border border-[#F84C6B] text-[#F84C6B] rounded-full shrink-0" />
											<span className="text-[#F84C6B]">{t(tKey('cancel'))}</span>
										</div>
									</div>
								</td>
							)}
							{isUpcomingAppointment(appointment.from, appointment.status) &&
							isWithinUpdateTime(appointment.from, appointment.status) ? (
								<td className="tw-table-td pl-0 border-r border-[#26B6A64D] rounded-r-lg">
									<div className="px-4 py-[11px]">
										<div
											onClick={event => {
												event.stopPropagation()
												setUpdatedAppointment(appointment)
											}}
											className="flex gap-x-1 cursor-pointer">
											<ArrowPathIcon className="h-4 w-4 border border-yellow-600 text-yellow-600 rounded-full shrink-0" />
											<span className="text-yellow-600">{t(tKey('update'))}</span>
										</div>
									</div>
								</td>
							) : (
								<td></td>
							)}
						</tr>
					)
				})}
			</tbody>
		)
	}

	const renderUpcomingAppointmentCards = (appointments: Appointment[]) => {
		return (
			<div className="flex flex-col gap-y-2">
				{appointments
					.filter(data => isUpcomingAppointment(data.from, data.status))
					.map(appointment => (
						<UpcomingAppointmentCard
							key={appointment._id}
							id={appointment._id}
							appointment={appointment}
							isDiscounted={appointment.is_discounted}
							additionalInfo={appointment.additonal_information}
							relatedPerson={appointment.relatedPerson}
							payment_mode={appointment.payment_mode}
							companyImage={appointment.id_company.logo_url}
							status={appointment.status as AppointmentStatus}
							date={appointment.from}
							title={appointment.company_name}
							questions={appointment.questions}
							onUpdate={(id: string) =>
								setUpdatedAppointment(
									appointments.find(appointment => appointment._id === id) as Appointment
								)
							}
							onCancel={(id: string) =>
								setDeletePrompt({ id: id, message: t(tKey('create.toast.cancelConfirm')) })
							}
							categories={appointment.id_company.company_type}
							service={appointment.service}
							company={appointment.id_company}
						/>
					))}
			</div>
		)
	}

	const renderPastAppointmentCards = (appointments: Appointment[]) => {
		return (
			<div className="flex flex-col gap-y-2">
				{appointments
					.filter(data => !isUpcomingAppointment(data.from, data.status))
					.map(appointment => (
						<PastAppointmentCard
							key={appointment._id}
							appointment={appointment}
							isDiscounted={appointment.is_discounted}
							payment_mode={appointment.payment_mode}
							id={appointment._id}
							additionalInfo={appointment.additonal_information}
							appointmentNumber={appointment.appointmentNumber}
							relatedPerson={appointment.relatedPerson}
							companyImage={appointment.id_company.logo_url}
							date={appointment.from}
							title={appointment.company_name}
							categories={appointment.id_company.company_type}
							service={appointment.service}
							company={appointment.id_company}
						/>
					))}
			</div>
		)
	}

	if (isLoading) {
		return (
			<AppLayout renderDashboardHeader>
				<div className="fixed top-1/2 -translate-y-1/2 h-full w-full left-1/2 -translate-x-1/2 bg-black bg-opacity-70 z-50">
					<div className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black -ml-9 text-xl animate-pulse">
							Please Wait...
						</span>
					</div>
				</div>
				<MobileNavigation />
			</AppLayout>
		)
	}

	return (
		<AppLayout renderDashboardHeader>
			{updatedAppointment && (
				<Modal
					width="w-[512px]"
					crossIconPosition="top-5 right-4"
					isFullHeight
					onClose={() => {
						setUpdatedAppointment(undefined)
						setSelectedDate('')
						setSelectedTimeslot('')
					}}
					showCrossIcon={!!isMobile}>
					<div className="flex flex-col gap-y-6">
						<h2 className="font-domine text-lg text-primary">{t(tKey('updateAppointment'))}</h2>
						<TimePicker
							companyId={updatedAppointment.id_company._id}
							timeSlots={timeSlots as { [day: number]: string[] }}
							todayTimeSlots={todayTimeSlots as { [day: number]: string[] }}
							duration={updatedAppointment?.service?.duration as string}
							selectedTimeSlot={selectedTimeSlot}
							setSelectedTimeSlot={setSelectedTimeslot}
							selectedDate={selectedDate as any}
							setSelectedDate={(date: string) => setSelectedDate(date)}
						/>
						<div className="flex items-center justify-end gap-x-6">
							{(!isMobile || (isMobile && !isUpdating)) && (
								<button
									type="button"
									onClick={() => {
										setSelectedDate('')
										setSelectedTimeslot('')
										setUpdatedAppointment(undefined)
									}}
									className="bg-white w-fit max-lg:-mt-2 border rounded border-primary py-3 px-11 flex-1 text-primary max-lg:text-sm">
									{t(tKey('cancel'))}
								</button>
							)}
							<Button
								disabled={!selectedTimeSlot}
								onClick={onUpdateAppointment}
								style={!selectedTimeSlot ? { background: 'rgb(156 163 175)' } : {}}
								className="w-fit max-lg:-mt-2 px-11 flex-1 py-3 border border-primary max-lg:text-sm">
								{isUpdating ? (
									<div className="flex items-center justify-center gap-x-5">
										<Spinner />
										<span className="animate-pulse whitespace-nowrap">
											{t(tKey('create.labels.pleaseWait'))}
										</span>
									</div>
								) : (
									<span>{t(tKey('buttons.confirm'))}</span>
								)}
							</Button>
						</div>
					</div>
				</Modal>
			)}
			{deletePrompt && (
				<Modal width={isMobile ? 'w-[344px]' : 'w-[512px]'} showCrossIcon={false}>
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-col max-lg:items-center lg:flex-row gap-x-4">
							<img src={DangerIcon} className="shrink-0 w-[40px] h-[40px]" />
							<div className="flex max-lg:items-center flex-col gap-y-2">
								<h2 className="font-domine text-lg text-primary">{t(tKey('cancelAppointment'))}</h2>
								<p className="text-[#7F9AB2] text-sm max-lg:text-center">{deletePrompt.message}</p>
							</div>
						</div>
						<div className="flex flex-col lg:flex-row lg:justify-end lg:items-center max-lg:gap-y-5 gap-x-3">
							<button
								onClick={() => setDeletePrompt(undefined)}
								className="button-shadow border border-[#D1D5DB] rounded-md px-[17px] py-[9px] text-sm text-[#7F9AB2] hover:text-black hover:border-black">
								{t('relatedPersons.modal.buttons.cancel')}
							</button>
							<button
								onClick={() => onCancelAppointment(deletePrompt.id)}
								className="button-shadow rounded-md px-[17px] py-[9px] bg-[#DC2626] hover:bg-red-700 text-white">
								{t(tKey('confirm'))}
							</button>
						</div>
					</div>
				</Modal>
			)}
			{showModal && selectedAppointment && (
				<Modal
					showCrossIcon={true}
					width="lg:w-[690px] w-full"
					onClose={() => {
						setShowModal(false)
						setSelectedAppointment(undefined)
					}}>
					<AppLayout renderDashboardHeader={isMobile}>
						<div className="flex flex-col lg:gap-y-6 gap-y-5 max-lg:mt-6">
							<div className="lg:pb-5 lg:border-b lg:border-[#D3E3F1] flex gap-x-3">
								<img
									src={selectedAppointment.id_company.logo_url}
									className="lg:w-[76px] lg:h-[76px] w-[65px] h-[61px] rounded-lg"
								/>

								<div className="flex flex-col gap-y-2 lg:grow">
									<h4 className="font-domine text-primary lg:text-[20px] text-lg font-bold lg:line-clamp-1 lg:whitespace-nowrap lg:overflow-hidden lg:text-ellipsis">
										{selectedAppointment.company_name}
									</h4>
									<div className="flex justify-between items-center">
										<div className="flex">
											{selectedAppointment.id_company.company_type
												?.slice(0, 2)
												.map((item, index) => (
													<div key={item} className="flex items-center">
														{index > 0 && (
															<div className="w-[10px] h-[10px] bg-[#D9D9D9] rounded-full mx-3" />
														)}
														<span className="text-secondary max-lg:text-sm font-semibold">
															{item}
														</span>
													</div>
												))}
										</div>
										<div className="flex gap-x-2 items-center whitespace-nowrap max-lg:hidden">
											<img src={InputCalendarIcon} className="w-[24px] h-[24px]" />
											<p className="text-sm text-secondary">
												{DateTime.fromMillis(selectedAppointment.from)
													.setLocale(appLanguage)
													.toFormat('cccc, dd. MMM yyyy')}
											</p>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none">
												<path
													d="M9.99935 18.3263C14.6017 18.3263 18.3327 14.5954 18.3327 9.993C18.3327 5.39063 14.6017 1.65967 9.99935 1.65967C5.39698 1.65967 1.66602 5.39063 1.66602 9.993C1.66602 14.5954 5.39698 18.3263 9.99935 18.3263Z"
													stroke="#123258"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M10 4.99316V9.99316L13.3333 11.6598"
													stroke="#123258"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
											<p className="text-sm text-secondary">
												{DateTime.fromMillis(selectedAppointment.from).toFormat('HH:mm')} -{' '}
												{DateTime.fromMillis(selectedAppointment.from)
													.plus({ minutes: Number(selectedAppointment.service.duration) })
													.toFormat('HH:mm')}
											</p>
										</div>
									</div>
									<div className="flex md:items-center max-md:flex-col max-md:gap-y-2 md:justify-between">
										<div className="flex gap-x-2 items-center">
											<h5 className="font-bold text-secondary">{t(tKey('bookingFor'))}:</h5>
											<p className="text-secondary">
												{selectedAppointment?.relatedPerson?.relation ??
													t('appointment.create.labels.myself')}
											</p>
										</div>
										<div className="flex gap-x-2 items-center">
											<h5 className="font-bold text-secondary">{t(tKey('paymentMethod'))}:</h5>
											<p className="text-secondary">
												{selectedAppointment?.payment_mode === 'onsite'
													? t(tKey('onsite'))
													: t(tKey('online'))}
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className=" border-b border-[#D3E3F1] text-sm pb-3.5 -mt-1 lg:hidden flex jus gap-x-2 items-center whitespace-nowrap">
								<img src={InputCalendarIcon} className="w-[24px] h-[24px]" />
								<p className="text-sm text-secondary">
									{DateTime.fromMillis(selectedAppointment.from)
										.setLocale(appLanguage)
										.toFormat('cccc, dd. MMM yyyy')}
								</p>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									className="shrink-0"
									fill="none">
									<path
										d="M9.99935 18.3263C14.6017 18.3263 18.3327 14.5954 18.3327 9.993C18.3327 5.39063 14.6017 1.65967 9.99935 1.65967C5.39698 1.65967 1.66602 5.39063 1.66602 9.993C1.66602 14.5954 5.39698 18.3263 9.99935 18.3263Z"
										stroke="#123258"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M10 4.99316V9.99316L13.3333 11.6598"
										stroke="#123258"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
								<p className="text-sm text-secondary">
									{DateTime.fromMillis(selectedAppointment.from).toFormat('HH:mm')} -{' '}
									{DateTime.fromMillis(selectedAppointment.from)
										.plus({ minutes: Number(selectedAppointment.service.duration) })
										.toFormat('HH:mm')}
								</p>
							</div>

							<div className="flex flex-col lg:gap-y-3.5 gap-y-2">
								<h4 className="font-domine lg:text-lg font-bold text-primary">
									{t('userDashboard.appointmentCard.services')}
								</h4>
								<div className="flex gap-x-3.5">
									<div className="px-3 py-0.5 rounded-full bg-[#D3E3F180] text-secondary">
										{selectedAppointment.service_name}
									</div>
								</div>
							</div>

							<div className="flex flex-col gap-y-3.5">
								<h4 className="font-domine text-lg font-bold text-primary">
									{t('userDashboard.appointmentCard.description')}
								</h4>
								<div className="flex gap-x-3.5">
									<div className="text-secondary">{selectedAppointment.service?.description}</div>
								</div>
							</div>

							<div className="flex flex-col lg:gap-y-3.5 gap-y-2">
								<h4 className="font-domine lg:text-lg font-bold text-primary">
									{t(tKey('additionalInformation'))}
								</h4>
								<p className="text-secondary">
									{selectedAppointment.additonal_information ??
										t('appointment.noAdditionalInformation')}
								</p>
							</div>

							<div className="p-2.5 shadow-xl mx-auto shadow-[#D3E3F1]">
								<ReactQRCode
									className="w-[164px] h-[164px]"
									value={`https://www.pro.stylingcorner.ch/appointment-details/${selectedAppointment._id}`}
								/>
							</div>
							<div className="flex gap-x-2.5 p-2 bg-[#FFFBEB] rounded items-center">
								<img src={ExclamationMarkIcon} />
								<p className="text-[#92400E] lg:text-sm text-xs">
									{t('userDashboard.appointmentCard.qrCodeAppointment')}
								</p>
							</div>
							<div className="flex flex-col lg:gap-y-5 gap-y-3.5">
								<h4 className="font-domine lg:text-lg font-bold text-primary">
									{t('userDashboard.appointmentCard.contactDetails')}
								</h4>
								<div className="flex flex-col gap-y-4">
									<div className="flex gap-x-2 max-lg:items-center">
										<img src={OutlinePhoneIcon} className="max-lg:h-6 max-lg:w-6" />
										<p className="text-primary max-lg:text-sm">
											{selectedAppointment.id_company?.phone}
										</p>
									</div>
									<div className="flex gap-x-2 max-lg:items-center">
										<img src={OutlineMailIcon} className="max-lg:h-6 max-lg:w-6" />
										<p className="text-primary max-lg:text-sm">
											{selectedAppointment.id_company?.email}
										</p>
									</div>
									<div className="flex gap-x-2 max-lg:items-center">
										<img src={OutlineGlobeIcon} className="max-lg:h-6 max-lg:w-6" />
										<p className="text-primary max-lg:text-sm">
											{selectedAppointment.id_company?.website}
										</p>
									</div>
									<div className="flex gap-x-2 max-lg:items-center">
										<MapPinIcon className="h-4 ml-1.5 w-4 text-[#5964D3]" />
										<p className="text-primary max-lg:text-sm">{`${selectedAppointment.id_company.address} ${selectedAppointment.id_company.address_no}, ${selectedAppointment.id_company.zip} ${selectedAppointment.id_company.city}`}</p>
									</div>
								</div>
							</div>

							<div className="flex flex-col gap-y-[13px]">
								<div className="py-[15px] lg:px-[131px] bg-[#D3E3F14D] text-primary items-center justify-center flex flex-col gap-y-2">
									<span className="text-sm">Total</span>
									<span className="font-bold font-domine text-sm">
										{getServiceDisplayPrice(selectedAppointment)}
									</span>
								</div>
							</div>
						</div>
					</AppLayout>
				</Modal>
			)}
			<div className="lg:px-[60px] bg-[#f0eff3] min-h-screen px-5 pt-8 max-lg:pb-16">
				<div className="flex flex-col gap-y-8">
					<Tabs
						tabs={[t(tKey('tabs.upcoming')), t(tKey('tabs.past'))]}
						setTab={(value: string) =>
							setSearchParams(
								{
									appointment:
										value === t(tKey('tabs.upcoming'))
											? AppointmentTabs.UPCOMING
											: AppointmentTabs.PAST
								},
								{ replace: true }
							)
						}
						selectedTab={
							searchParams.get('appointment') === AppointmentTabs.PAST
								? t(tKey('tabs.past'))
								: t(tKey('tabs.upcoming'))
						}
					/>
					{!isMobile && (
						<Table
							headers={
								searchParams.get('appointment') === AppointmentTabs.PAST
									? [
											t(tKey('company')),
											'',
											t(tKey('appointmentNumber')),
											t(tKey('service')),
											t(tKey('date')),
											t(tKey('time')),
											t(tKey('price')),
											t(tKey('status')),
											''
										]
									: [
											t(tKey('company')),
											'',
											t(tKey('service')),
											t(tKey('date')),
											t(tKey('time')),
											t(tKey('price')),
											t(tKey('status')),
											'',
											'',
											''
										]
							}
							items={
								searchParams.get('appointment') === AppointmentTabs.UPCOMING
									? appointments.filter(data => isUpcomingAppointment(data.from, data.status))
									: appointments.filter(data => !isUpcomingAppointment(data.from, data.status))
							}
							renderComponent={renderAppointmentsTBody}
						/>
					)}
					{isMobile && (
						<Pagination
							items={
								searchParams.get('appointment') === AppointmentTabs.UPCOMING
									? appointments.filter(data => isUpcomingAppointment(data.from, data.status))
									: appointments.filter(data => !isUpcomingAppointment(data.from, data.status))
							}
							renderComponent={
								searchParams.get('appointment') === AppointmentTabs.UPCOMING
									? renderUpcomingAppointmentCards
									: renderPastAppointmentCards
							}
						/>
					)}
				</div>
			</div>
			<Footer className="max-lg:mb-16 max-lg:-mt-10" />
			<MobileNavigation />
		</AppLayout>
	)
}
