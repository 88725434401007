import { Disclosure } from '@headlessui/react'
import { ArrowUpRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

import clsx from 'clsx'

import { Breadcrumb } from 'components/app/breadcrumb'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { useAppSelector } from 'hooks'
import { dataCenterContent } from 'pages/datacenter/data'
import { useNavigate } from 'react-router'

export const DataCenter = () => {
	const navigate = useNavigate()
	const auth = useAppSelector(state => state.auth)

	return (
		<AppLayout renderHeader={!auth.accessToken} renderDashboardHeader={!!auth.accessToken}>
			<div className="bg-datacenter bg-no-repeat bg-center bg-cover h-[193px] lg:h-[283px] w-full px-5 lg:px-[60px] py-[18px]">
				<Breadcrumb
					options={[{ name: 'Datenschutzzentrum', href: '/datenschutzzentrum', current: true }]}
				/>
				<div className="flex flex-col justify-center items-center w-full h-full">
					<h1 className="lg:text-[40px] text-2xl font-bold font-domine text-white">
						Datenschutzzentrum
					</h1>
				</div>
			</div>
			<div className="flex flex-col lg:flex-row gap-x-14 px-5 lg:px-[60px] pt-[66px] pb-14 lg:pb-[100px]">
				<div className="lg:basis-[30%] 2xl:basis-[35%]">
					<h4 className="bg-[#d3e3f14d] text-primary max-lg:text-sm font-domine font-bold mb-2 lg:mb-3.5 py-2 lg:py-3.5 px-4">
						Datenschutzzentrum
					</h4>
					{dataCenterContent
						.filter(content => content.subMenu && content.subMenu?.length > 0)
						.slice(0, -1)
						.map(content => (
							<Disclosure key={content.name}>
								{({ open }) => (
									<>
										<Disclosure.Button
											className={clsx(
												'flex text-left justify-between items-center w-full rounded-xl py-2 max-lg:text-sm lg:py-3.5 px-4 text-primary font-domine font-bold',
												open ? 'bg-[#D3E3F1]' : 'bg-[#d3e3f14d] mb-2 lg:mb-3.5'
											)}>
											<span>{content.name}</span>
											<ChevronDownIcon
												className={clsx('h-5 w-5 shrink-0 text-primary', {
													'rotate-180 transform': open
												})}
											/>
										</Disclosure.Button>
										<Disclosure.Panel
											className={clsx('flex flex-col gap-y-2.5 py-3 px-8 ', {
												'mb-2 lg:mb-3.5': open
											})}>
											{content.subMenu &&
												content.subMenu.map(item => (
													<ul
														onClick={() => {
															if (item === 'Kontakt des DSB') {
																return (window.location.href =
																	'mailto:m.bindschedler@resolut-technologies.ch')
															}
															navigate(item)
														}}
														className="cursor-pointer list-disc max-lg:text-sm hover:text-[#13BAB4]"
														key={item}>
														<li>{item}</li>
													</ul>
												))}
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						))}
				</div>
				<div className="lg:basis-[70%] max-lg:mt-16 2xl:basis[65%] grid grid-cols-1 grid-rows-4 lg:grid-cols-2 lg:grid-rows-2 gap-10">
					{dataCenterContent
						.filter(content => content.image !== undefined)
						.map(content => (
							<div
								onClick={() => {
									if (content.subMenu?.[0] === 'Kontakt des DSB') {
										return (window.location.href = 'mailto:m.bindschedler@resolut-technologies.ch')
									}
									navigate(content.subMenu?.[0])
								}}
								className="flex flex-col gap-y-6 cursor-pointer"
								key={content.name}>
								<img
									src={content.image}
									alt={content.name}
									className="w-full h-auto object-cover rounded-xl"
								/>
								<div className="flex justify-between items-end lg:items-start">
									<div className="flex flex-col gap-y-4">
										<h5 className="text-[#13BAB4] max-lg:text-xs max-2xl:text-sm w-fit font-semibold rounded-full px-3 py-1 bg-[#d3e3f14d]">
											{content.tagline}
										</h5>
										<h1 className="font-domine text-xl font-bold text-primary">
											{content.subMenu?.[0]}
										</h1>
									</div>
									<div className="rounded-full bg-primary-light p-2.5 cursor-pointer">
										<ArrowUpRightIcon className="h-5 w-5 stroke-white" />
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
			<Footer />
		</AppLayout>
	)
}
