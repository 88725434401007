import { addDoc, collection, Timestamp } from 'firebase/firestore'
import { db } from './config'

const addJobToFirebase = async (appointment: Appointment) => {
	try {
		await addDoc(collection(db, 'jobs'), {
			title: 'Appointment Created',
			appointmentsData: appointment,
			status: 'New',
			createdAt: Timestamp.now()
		})
	} catch (error) {
		console.error('Error adding job:', error)
	}
}

export default addJobToFirebase
