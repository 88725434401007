import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { AppLayout } from 'components/app/layout'
import { useAppSelector } from 'hooks'
import companyService from 'services/company-service'
import { getTKey } from 'utils/language'

export const CompanyAGB = () => {
	const params = useParams()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const tKey = getTKey('agb')

	const companyId = params.id

	const auth = useAppSelector(state => state.auth)

	const [company, setCompany] = useState<Company>()

	useEffect(() => {
		if (companyId) {
			companyService.getCompanyById(companyId).then(res => setCompany(res))
		}
	}, [companyId])

	return (
		<AppLayout
			onLogoClick={() => navigate(`/company-details/${companyId}`)}
			renderDashboardHeader={!!auth.accessToken}
			renderHeader={!auth.accessToken}>
			<div className="w-11/12 md:w-10/12 mx-auto flex flex-col gap-y-10">
				<div className="flex py-4 border-b border-dashed border-[#D3E3F1] gap-x-4 w-full items-start">
					{company?.logo_url && (
						<img
							src={company?.logo_url}
							className="sm:w-[100px] w-[50px] shrink-0 h-[58px] object-cover"
						/>
					)}
					<div className="flex flex-col">
						<h2 className="text-primary font-domine text-lg md:text-xl font-bold capitalize">
							{company?.company_name}
						</h2>
						<div className="flex">
							<p className="text-sm text-[#757575] ">
								{company?.company_type.slice(0, 3).join(', ')}
							</p>
						</div>
					</div>
				</div>

				{company?.agb && (
					<div className="flex flex-col gap-y-4 md:gap-y-8 pb-10">
						<h1 className="text-xl md:text-3xl text-center font-bold font-domine">
							{t(tKey('heading.termsAndConditions'))}
						</h1>
						<div
							className="faq max-md:text-sm max-md:px-3 text-justify"
							dangerouslySetInnerHTML={{ __html: company.agb }}
						/>
					</div>
				)}
			</div>
		</AppLayout>
	)
}
