import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Spinner } from 'components/animations/spinner'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { MobileNavigation } from 'components/app/mobile-navigation'
import { AppointmentCard } from 'components/cards/appointment-card'
import { CompanyCard } from 'components/cards/company-card'
import appointmentService from 'services/appointment-service'
import companyService from 'services/company-service'
import userService from 'services/user-service'
import { getTKey } from 'utils/language'

export const UserDashboard = () => {
	const { t } = useTranslation()
	const tKey = getTKey('userDashboard')

	const [user, setUser] = useState<User>()
	const [isLoading, setIsLoading] = useState(true)
	const [appointments, setAppointments] = useState<Appointment[]>([])
	const [companies, setCompanies] = useState<SearchResult[]>([])
	const [appointmentStats, setAppointmentStats] = useState<{ title: string; quantity: number }[]>(
		[]
	)

	useEffect(() => {
		userService.getUserInfo().then(res => setUser(res))
		appointmentService.getAppointmentStats().then(res =>
			setAppointmentStats([
				{
					title: t(tKey('appointments.totalAppointments')),
					quantity: res.totalAppointment
				},
				{
					title: t(tKey('appointments.successFulAppointments')),
					quantity: res.schlusskontrolle
				},
				{
					title: t(tKey('appointments.cancelledAppointments')),
					quantity: res.canceled
				}
			])
		)

		companyService.searchCompanies().then(res => setCompanies(res.data))
	}, [])

	useEffect(() => {
		if (user?._id) {
			appointmentService
				.getAppointmentByUserId()
				.then(response => setAppointments(response))
				.finally(() => setIsLoading(false))
		}
	}, [user?._id])

	if (isLoading) {
		return (
			<AppLayout renderDashboardHeader>
				<div className="fixed top-1/2 -translate-y-1/2 h-full w-full left-1/2 -translate-x-1/2 bg-black bg-opacity-70 z-50">
					<div className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black -ml-9 text-xl animate-pulse">
							{t(tKey('labels.pleaseWait'))}
						</span>
					</div>
				</div>
				<MobileNavigation />
			</AppLayout>
		)
	}

	return (
		<AppLayout renderDashboardHeader>
			<div className="lg:px-14 bg-[#f0eff3] min-h-screen px-[21px] pt-8 lg:pb-10">
				<div className="flex lg:flex-row flex-col max-lg:gap-y-8 lg:gap-x-8">
					<div className="flex flex-col gap-y-8 w-full lg:w-[65%] grow">
						<div className="flex justify-center gap-x-[11px] lg:gap-x-8">
							{appointmentStats.map((stat, index) => (
								<div
									key={stat.title}
									className="flex relative bg-white flex-col basis-1/3 max-lg:w-[104px] max-lg:h-[90px] max-lg:p-3 lg:px-6 lg:py-5 gap-y-3.5 rounded-[14px]">
									<h3 className="text-primary max-lg:text-xs font-semibold lg:w-20 2xl:w-full">
										{stat.title}
									</h3>
									<p className="lg:text-[40px] text-[#B790D8] text-xl sm:text-[24px] font-medium">
										{stat.quantity}
									</p>
									<div className="bg-[#7094F4] max-lg:size-3 lg:h-6 lg:w-6 absolute top-2 rounded-full right-2" />
								</div>
							))}
						</div>

						<div className="flex flex-col gap-y-5">
							<h3 className="font-bold font-domine text-primary">
								{t(tKey('labels.recentAppointments'))}
							</h3>
							{appointments
								.slice(0, 3)
								?.map(appointment => (
									<AppointmentCard
										key={appointment._id}
										id={appointment._id}
										appointment={appointment}
										isDiscounted={appointment.is_discounted}
										payment_mode={appointment.payment_mode}
										date={appointment.from}
										additionalInfo={appointment.additonal_information}
										relatedPerson={appointment.relatedPerson}
										questions={appointment.questions}
										companyImage={appointment.id_company?.logo_url}
										title={appointment.id_company?.company_name}
										categories={appointment.id_company?.company_type}
										service={appointment.service}
										company={appointment.id_company}
									/>
								))}
						</div>
					</div>

					<div className="flex flex-col gap-y-8 w-full lg:max-w-[35%] max-lg:mb-[100px]">
						<div className="flex flex-col gap-y-5">
							<div className="flex justify-between items-center">
								<div className="font-domine text-primary font-bold text-lg">
									{t(tKey('labels.topRatedCompanies'))}
								</div>
							</div>
							{companies.slice(0, 3).map((company, index) => (
								<CompanyCard
									key={index}
									id={company._id}
									image={company.logo_url}
									title={company.company_name}
									address={`${company.address} ${company.address_no}, ${company.zip} ${company.city}`}
									categories={company.company_type}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
			<Footer className="max-lg:mb-16 max-lg:-mt-10" />
			<MobileNavigation />
		</AppLayout>
	)
}
