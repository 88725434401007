import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import { SignatureIcon, SMSIcon } from 'assets/icons'
import { ReactComponent as InstagramIcon } from 'assets/svgs/footer-instagram.svg'
import { ReactComponent as LinkedinIcon } from 'assets/svgs/footer-linkedin.svg'
import { ReactComponent as TiktokIcon } from 'assets/svgs/footer-tiktok.svg'
import { getTKey } from 'utils/language'

interface FooterProps {
	className?: string
}

export const Footer = ({ className }: FooterProps) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const tKey = getTKey('footer')

	return (
		<div
			className={clsx(
				'w-full bg-[#070027F7] text-white flex flex-col gap-8 px-5 md:px-[120px] py-14',
				className
			)}>
			<div className="grid grid-cols-1 md:grid-cols-3 items-start gap-6">
				<div className="flex flex-col gap-4">
					<h3 className="font-medium md:text-lg leading-6">Styling Corner ®</h3>
					<p className="text-sm md:text-base leading-6 text-white/70">Über Styling Corner</p>
					<Link
						target="__blank"
						to="https://resoluttechnologies-assist.freshdesk.com/support/tickets/new"
						className="text-sm md:text-base leading-6 text-white/70">
						{t(tKey('customerHelpCenter'))}
					</Link>
					<p className="text-sm md:text-base leading-6 text-white/70">Hilfebereich</p>
					<p className="text-sm md:text-base leading-6 text-white/70">Cookie Einstellungen</p>
					<p className="text-sm md:text-base leading-6 text-white/70">FAQs</p>
				</div>

				<div className="flex flex-col gap-4">
					<h3 className="font-medium md:text-lg leading-6">Jetzt entdecken</h3>
					<Link to="/articles" className="text-sm md:text-base leading-6 text-white/70">
						{t(tKey('newsCenter'))}
					</Link>
					<Link
						target="__blank"
						to="https://resoluttechnologies-assist.freshdesk.com/support/tickets/new"
						className="text-sm md:text-base leading-6 text-white/70">
						{t(tKey('customerHelpCenter'))}
					</Link>
					<Link to="/coupon" className="text-sm md:text-base leading-6 text-white/70">
						{t(tKey('stylingCornerPoints'))}
					</Link>
					<p className="text-sm md:text-base leading-6 text-white/70">
						Styling Corner Geschenkgutschein
					</p>
					<p
						onClick={() => navigate('/search-results', { state: 'newest' })}
						className="text-sm cursor-pointer md:text-base leading-6 text-white/70">
						Neue Einträge
					</p>
					<p
						onClick={() => navigate('/search-results', { state: 'topRated' })}
						className="text-sm cursor-pointer md:text-base leading-6 text-white/70">
						TOP Bewertungen
					</p>
					<p
						onClick={() => navigate('/search-results', { state: 'discounted' })}
						className="text-sm cursor-pointer md:text-base leading-6 text-white/70">
						{t(tKey('bestDeals'))}
					</p>
				</div>

				<div className="flex flex-col gap-4">
					<h3 className="font-medium md:text-lg leading-6">Für Unternehmen</h3>
					<p className="text-sm md:text-base leading-6 text-white/70">
						Eintrag erstellen / ändern / löschen
					</p>
					<p className="text-sm md:text-base leading-6 text-white/70">Styling Corner Help Center</p>
					<Link
						target="_blank"
						to="https://stylingcorner.pro"
						className="text-sm md:text-base leading-6 text-white/70">
						Styling Corner PRO Software
					</Link>
					<p className="text-sm md:text-base leading-6 text-white/70">Fachrichtungen / Branches</p>
					<p className="text-sm md:text-base leading-6 text-white/70">Marketing Center</p>
					<p className="text-sm md:text-base leading-6 text-white/70">Werbeflächen</p>
				</div>
			</div>

			<div className="py-9 border-y border-white/20 flex max-md:flex-col max-md:gap-6 items-center md:justify-between">
				<div className="flex max-md:flex-col items-center gap-6">
					<img
						src={SignatureIcon}
						className="rounded-md h-[60px] w-[178px] cursor-pointer object-contain"
					/>
					<img
						src={SMSIcon}
						className="rounded-md h-[60px] w-[178px] cursor-pointer object-contain"
					/>
				</div>
				<div className="flex items-center gap-6">
					<Link target="__blank" to="https://www.instagram.com/stylingcorner_schweiz/">
						<InstagramIcon />
					</Link>
					<Link target="__blank" to="https://www.linkedin.com/company/styling-corner%C2%AE">
						<LinkedinIcon />
					</Link>
					<Link target="__blank" to="https://www.tiktok.com/@styling.corner?_t=8rWoHY1RLVI&_r=1">
						<TiktokIcon />
					</Link>
				</div>
			</div>

			<div className="flex max-md:flex-col max-md:gap-6 md:items-center md:justify-between">
				<p className="text-sm md:text-base leading-6">
					© {new Date().getFullYear()} Styling Corner ® Brand der Resolut Technologies GmbH |{' '}
					<span
						onClick={() => navigate('/auth')}
						className="text-primary-light cursor-pointer hover:underline">
						Kunden Login
					</span>{' '}
				</p>
				<div className="flex items-center gap-4">
					<Link
						to="/datenschutzzentrum"
						className="text-primary-light text-sm md:text-base leading-6">
						AGB's
					</Link>
					<p>|</p>
					<Link
						to="/datenschutzzentrum"
						className="text-primary-light text-sm md:text-base leading-6">
						Datenschutzzentrum
					</Link>
					<p>|</p>
					<p className="text-primary-light text-sm md:text-base leading-6">Impressum</p>
				</div>
			</div>
		</div>
	)
}
