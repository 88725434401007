import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import authService from 'services/auth-service'
import { getTKey } from 'utils/language'

interface SubscriptionBoxProps {
	type?: 'coupon' | 'home'
}

export const SubscriptionBox = ({ type = 'home' }: SubscriptionBoxProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const tKey = getTKey('home')

	const [email, setEmail] = useState('')

	const subscribeToNewsletter = (event: React.FormEvent) => {
		event.preventDefault()
		authService
			.subscribeToNewsLetter(email)
			.then(() => {
				toast.success(t(tKey('labels.subscribedToNewsletter')))
				setEmail('')
			})
			.catch(error =>
				toast.error(error?.response?.data?.message ?? t(tKey('labels.errorSubscribing')))
			)
	}

	return (
		<div className="bg-[#070027] text-white py-8 px-6 md:p-8 rounded-xl flex flex-col md:flex-row items-center justify-between w-full max-w-7xl mx-auto">
			<div className="mb-6 w-full md:mb-0 text-left">
				<h2 className="text-2xl md:text-4xl font-bold">
					{type === 'coupon' ? t('coupon.titles.styleMore') : t(tKey('headings.getLatestUpdates'))}
				</h2>
				<p
					className="text-md md:text-md mt-2"
					dangerouslySetInnerHTML={{
						__html:
							type === 'coupon' ? t('coupon.labels.rewardingVisit') : t(tKey('labels.shareDetails'))
					}}
				/>
			</div>

			{type === 'coupon' ? (
				<button
					onClick={() => navigate('/search-results')}
					className="bg-[#5964D3] whitespace-nowrap text-white py-3 px-6 rounded-lg hover:bg-[#7D8AE6] focus:outline-none shadow-lg">
					{t('coupon.buttons.bookAppointment')}
				</button>
			) : (
				<form onSubmit={subscribeToNewsletter} className="flex items-center w-full md:w-[50%]">
					<input
						type="email"
						value={email}
						required
						onChange={event => setEmail(event.target.value)}
						placeholder={t(tKey('placeholders.enterEmail'))}
						className="w-full p-3 rounded-lg text-black outline-none"
					/>
					<button
						type="submit"
						className="bg-[#5964D3] text-white py-3 px-6 ml-4 rounded-lg hover:bg-[#7D8AE6] focus:outline-none shadow-lg">
						{t(tKey('buttons.subscribe'))}
					</button>
				</form>
			)}
		</div>
	)
}
