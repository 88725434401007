import axios from 'axios'
import { apiHost } from 'utils/host'
import { indexedStorageDB } from 'utils/local-forage'

const register = (data: Auth & { phone: string }) => {
	return axios.post(apiHost + '/auth/client/signup', data).then(res => res.data)
}

const subscribeToNewsLetter = (email: string) => {
	return axios.post(apiHost + '/subscribe', { email }).then(response => response.data)
}

const sendMobileVerification = (
	phonenumber: string,
	flag: boolean,
	password?: string,
	email?: string
) => {
	return axios
		.post(apiHost + '/auth/client/sendMobileVerify', { phonenumber, flag, password, email })
		.then(res => res.data)
}

const sendRegistrationEmail = (data: Partial<Auth> & { name: string; phone: string }) => {
	return axios.post(apiHost + '/auth/client/sendUserData', data).then(res => res.data)
}
const mobileVerification = (phonenumber: string, code: string) => {
	return axios
		.post(apiHost + '/auth/client/mobileVerify', { phonenumber, code })
		.then(res => res.data)
}

const forgotPassword = (email?: string, phone?: string) => {
	return axios
		.post(apiHost + '/auth/client/sendpassword', { ...(email ? { email } : { phone }) })
		.then(res => res.data)
}

const forgotPasswordMobileVerification = (phone: string, otp: string) => {
	return axios.post(apiHost + '/auth/client/mobileVerifyOnly', { phone, otp }).then(res => res.data)
}

const resetPassword = (new_password: string, otp: string, email?: string, phone?: string) => {
	return axios
		.post(apiHost + '/auth/client/verifypassword', {
			...(email ? { email } : { phone }),
			new_password,
			otp
		})
		.then(res => res.data)
}

const login = (username: string, password: string) => {
	return axios
		.post(apiHost + '/auth/client/signin', {
			username,
			password
		})
		.then(response => {
			return response.data
		})
}

const logout = () => {
	indexedStorageDB.clear()
	window.location.reload()
}

const authService = {
	register,
	login,
	forgotPassword,
	subscribeToNewsLetter,
	forgotPasswordMobileVerification,
	resetPassword,
	sendMobileVerification,
	sendRegistrationEmail,
	mobileVerification,
	logout
}

export default authService
