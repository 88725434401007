import { XMarkIcon } from '@heroicons/react/20/solid'
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ClockIcon,
	HeartIcon,
	MagnifyingGlassIcon,
	MapPinIcon
} from '@heroicons/react/24/outline'
import * as Slider from '@radix-ui/react-slider'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useTranslation } from 'react-i18next'
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { Article2, Category1, Category2, Category3 } from 'assets/icons'
import { ReactComponent as FilterIcon } from 'assets/svgs/filter-icon.svg'
import { AppLayout } from 'components/app/layout'
import { Modal } from 'components/app/modal'
import { SearchSuggestion } from 'components/home/search-box'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { Map } from 'pages/search/map'
import companyService from 'services/company-service'
import { isOpen } from 'utils/date'
import { getAppLang, getTKey } from 'utils/language'

import { Spinner } from 'components/animations/spinner'
import { toast } from 'react-toastify'
import userService from 'services/user-service'
import 'styles/search.css'

export const Search = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { t } = useTranslation()
	const location = useLocation()
	const isMobile = useIsMobile()
	const searchRef = useRef<HTMLDivElement>(null)

	const tKey = getTKey('search')

	const auth = useAppSelector(state => state.auth)

	const defaultFilterOptions = {
		topRated: location.state === 'topRated' ? true : undefined,
		recommended: undefined,
		newest: location.state === 'newest' ? true : undefined,
		discounted: location.state === 'discounted' ? true : undefined,
		favorites: undefined,
		price: undefined
	}

	const [currentPage, setCurrentPage] = useState(1)
	const [isLoading, setIsLoading] = useState(true)
	const [currentLocation, setCurrentLocation] = useState<{ lat: number; lng: number } | null>(null)
	const [searchResultMeta, setSearchResultMeta] = useState<SearchResultMeta>()
	const [isFocus, setIsFocus] = useState(false)
	const [openFilterModal, setOpenFilterModal] = useState(false)
	const [name, setName] = useState(searchParams.get('name') ?? '')
	const [country, setCountry] = useState(searchParams.get('country') ?? '')
	const [forceRefresh, setForceRefresh] = useState(false)
	const [favouriteCompanies, setFavouriteCompanies] = useState<Company[]>([])
	const [searchResults, setSearchResults] = useState<SearchResult[]>([])
	const [centerPosition, setCenterPosition] = useState<{ lat: number; lng: number }>()
	const [suggestions, setSuggestions] = useState({
		companies: [],
		companyTypes: [],
		services: []
	})
	const [filterOptions, setFilterOptions] = useState<{
		topRated: undefined | boolean
		recommended: undefined | boolean
		newest: undefined | boolean
		discounted: undefined | boolean
		favorites: undefined | boolean
		price: number | undefined
	}>(defaultFilterOptions)

	const { ref } = usePlacesWidget({
		apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		options: {
			types: ['(regions)'],
			componentRestrictions: { country: 'ch' }
		},
		onPlaceSelected: place => {
			setCountry(place?.formatted_address ?? '')
		}
	})

	useEffect(() => {
		const handleCurrentLocation = () => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					position => {
						const userLocation = {
							lat: position.coords.latitude,
							lng: position.coords.longitude
						}
						setCurrentLocation(userLocation)
					},
					error => {
						console.error('Error getting location:', error)
					}
				)
			} else {
				alert('Geolocation is not supported by this browser.')
			}
		}
		companyService
			.searchCompanies(
				searchParams.get('name') as string,
				searchParams.get('country') as string,
				undefined,
				currentPage,
				filterOptions.topRated,
				filterOptions.recommended,
				filterOptions.newest,
				filterOptions.discounted
			)
			.then(res => {
				setSearchResults(res.data)
				setSearchResultMeta(res.meta)
			})
			.finally(() => setIsLoading(false))
		handleCurrentLocation()
	}, [])

	useEffect(() => {
		if (auth.accessToken) {
			userService.getFavourites().then(res => setFavouriteCompanies(res))
		}
	}, [forceRefresh, auth])

	useEffect(() => {
		companyService.getSearchSuggestions(name).then(res =>
			setSuggestions({
				companies: res.companies?.companies,
				companyTypes: res.company_types?.company_types,
				services: res.services?.services
			})
		)
	}, [name])

	const showSuggestions = Object.values(suggestions).some(s => s.length > 0)

	const handleSearch = () => {
		setIsLoading(true)
		setCurrentPage(1)
		companyService
			.searchCompanies(
				name,
				country,
				undefined,
				1,
				filterOptions.topRated,
				filterOptions.recommended,
				filterOptions.newest,
				filterOptions.discounted,
				filterOptions.favorites,
				auth.id,
				filterOptions.price
			)
			.then(res => {
				setSearchResults(res.data)
				setSearchResultMeta(res.meta)
				setSearchParams(searchParams, { replace: true })
				searchParams.delete('name')
				searchParams.delete('country')
				setOpenFilterModal(false)
			})
			.finally(() => setIsLoading(false))
	}

	useEffect(() => {
		searchRef.current?.scrollTo(0, 0)
		setIsLoading(true)
		companyService
			.searchCompanies(
				name,
				country,
				undefined,
				currentPage,
				filterOptions.topRated,
				filterOptions.recommended,
				filterOptions.newest,
				filterOptions.discounted,
				filterOptions.favorites,
				auth.id,
				filterOptions.price
			)
			.then(res => {
				setSearchResults(res.data)
				setSearchResultMeta(res.meta)
				setSearchParams(searchParams, { replace: true })
				searchParams.delete('name')
				searchParams.delete('country')
				setOpenFilterModal(false)
			})
			.finally(() => setIsLoading(false))
	}, [currentPage])

	const handleFilter = () => {
		setIsLoading(true)
		setCurrentPage(1)
		companyService
			.searchCompanies(
				name ?? searchParams.get('name'),
				country ?? searchParams.get('country'),
				undefined,
				1,
				filterOptions.topRated,
				filterOptions.recommended,
				filterOptions.newest,
				filterOptions.discounted,
				filterOptions.favorites,
				auth.id,
				filterOptions.price
			)
			.then(res => {
				setSearchResults(res.data)
				setSearchResultMeta(res.meta)
				setOpenFilterModal(false)
			})
			.finally(() => setIsLoading(false))
	}

	const handleReset = () => {
		setIsLoading(true)
		setFilterOptions(defaultFilterOptions)
		setCurrentPage(1)
		companyService
			.searchCompanies(name, country, undefined, 1)
			.then(res => {
				setSearchResults(res.data)
				setSearchResultMeta(res.meta)
				setOpenFilterModal(false)
			})
			.finally(() => setIsLoading(false))
	}

	const isFilterApplied = Object.values(filterOptions).some(value => value !== undefined)

	const totalPages = searchResultMeta?.totalPages ?? 1

	let pageArray = [0, 1, 2].map(v => currentPage + v).filter(page => page > 0 && page <= totalPages)

	if (currentPage > totalPages - 3) {
		pageArray = [-1, 0, 1, 2]
			.map(v => currentPage + v)
			.filter(page => page > 0 && page <= totalPages)
	}
	if (currentPage > totalPages - 2) {
		pageArray = [-2, -1, 0, 1, 2]
			.map(v => currentPage + v)
			.filter(page => page > 0 && page <= totalPages)
	}
	if (currentPage > totalPages - 1) {
		pageArray = [-3, -2, -1, 0, 1, 2, 3]
			.map(v => currentPage + v)
			.filter(page => page > 0 && page <= totalPages)
	}

	return (
		<AppLayout
			className="h-screen overflow-hidden"
			wrapperClassName="h-full flex flex-col"
			renderHomeHeader={!auth.accessToken}
			renderDashboardHeader={!!auth.accessToken}>
			{openFilterModal && (
				<Modal
					width={isMobile ? 'w-full' : 'w-[521px]'}
					isFullHeight
					className="lg:!rounded-[20px]"
					noPadding
					showCrossIcon={false}>
					<div className="bg-[#5964D31F] flex items-center justify-between border-b border-[#F5F7FA] py-4 px-6">
						<h1 className="text-[#001E00] font-domine font-bold text-lg lg:text-xl">
							{t(tKey('titles.filters'))}
						</h1>
						<XMarkIcon
							onClick={() => {
								setFilterOptions(defaultFilterOptions)
								setOpenFilterModal(false)
							}}
							className="size-5 lg:size-6 text-[#001E00] cursor-pointer"
						/>
					</div>
					<div className="p-6 flex flex-col gap-6">
						<h2 className="text-[#001E00] font-domine font-bold">{t(tKey('labels.sorting'))}</h2>
						<div className="flex flex-col gap-3">
							<div className="flex gap-2.5 items-center">
								<input
									type="radio"
									id="recommended"
									name="sorting"
									checked={!!filterOptions.recommended}
									onChange={event =>
										setFilterOptions(prev => ({
											...prev,
											recommended: event.target.checked,
											discounted: !event.target.checked,
											newest: !event.target.checked,
											favorites: !event.target.checked,
											topRated: !event.target.checked
										}))
									}
									className="bg-[#CFD8DC] border-0 size-6 checked:bg-primary-light checked:ring-0 ring-0"
								/>
								<label htmlFor="recommended" className="text-primary font-medium">
									{t(tKey('labels.recommended'))}
								</label>
							</div>
							<div className="flex gap-2.5 items-center">
								<input
									type="radio"
									id="top-rated"
									checked={!!filterOptions.topRated}
									onChange={event =>
										setFilterOptions(prev => ({
											...prev,
											topRated: event.target.checked,
											discounted: !event.target.checked,
											newest: !event.target.checked,
											favorites: !event.target.checked,
											recommended: !event.target.checked
										}))
									}
									name="sorting"
									className="bg-[#CFD8DC] border-0 size-6 checked:bg-primary-light checked:ring-0 ring-0"
								/>
								<label htmlFor="top-rated" className="text-primary font-medium">
									{t(tKey('labels.topRated'))}
								</label>
							</div>
							<div className="flex gap-2.5 items-center">
								<input
									type="radio"
									id="newest"
									checked={!!filterOptions.newest}
									onChange={event =>
										setFilterOptions(prev => ({
											...prev,
											newest: event.target.checked,
											topRated: !event.target.checked,
											discounted: !event.target.checked,
											favorites: !event.target.checked,
											recommended: !event.target.checked
										}))
									}
									name="sorting"
									className="bg-[#CFD8DC] border-0 size-6 checked:bg-primary-light checked:ring-0 ring-0"
								/>
								<label htmlFor="newest" className="text-primary font-medium">
									{t(tKey('labels.newest'))}
								</label>
							</div>
							<div className="flex gap-2.5 items-center">
								<input
									type="radio"
									id="discounted"
									checked={!!filterOptions.discounted}
									onChange={event =>
										setFilterOptions(prev => ({
											...prev,
											discounted: event.target.checked,
											topRated: !event.target.checked,
											newest: !event.target.checked,
											favorites: !event.target.checked,
											recommended: !event.target.checked
										}))
									}
									name="sorting"
									className="bg-[#CFD8DC] border-0 size-6 checked:bg-primary-light checked:ring-0 ring-0"
								/>
								<label htmlFor="discounted" className="text-primary font-medium">
									{t(tKey('labels.discounted'))}
								</label>
							</div>
							{auth.accessToken && (
								<div className="flex gap-2.5 items-center">
									<input
										type="radio"
										id="favorites"
										checked={!!filterOptions.favorites}
										onChange={event =>
											setFilterOptions(prev => ({
												...prev,
												favorites: event.target.checked,
												discounted: !event.target.checked,
												topRated: !event.target.checked,
												newest: !event.target.checked,
												recommended: !event.target.checked
											}))
										}
										name="sorting"
										className="bg-[#CFD8DC] border-0 size-6 checked:bg-primary-light checked:ring-0 ring-0"
									/>
									<label htmlFor="favorites" className="text-primary font-medium">
										{t(tKey('labels.favorites'))}
									</label>
								</div>
							)}
						</div>
						<div className="w-full h-px border-t border-dashed border-[#CFD8DC]" />
						<h2 className="text-[#001E00] font-domine font-bold">{t(tKey('labels.filterBy'))}</h2>
						<div className="flex flex-col gap-3">
							<div className="flex items-center justify-between">
								<h2 className="text-primary font-medium">{t(tKey('labels.price'))}</h2>
								{filterOptions.price && (
									<h2 className="text-primary font-medium">
										CHF {new Intl.NumberFormat('en-US').format(filterOptions.price)}
									</h2>
								)}
							</div>
							<Slider.Root
								value={[filterOptions.price ?? 0]}
								max={3000}
								min={25}
								step={1}
								onValueChange={value => setFilterOptions(prev => ({ ...prev, price: value[0] }))}
								className="relative flex touch-none select-none items-center h-1 w-full">
								<Slider.Track className="relative h-1 grow rounded-full bg-[#E9F1F8]">
									<Slider.Range className="absolute h-full rounded-full bg-primary-light" />
								</Slider.Track>
								<Slider.Thumb
									className="block size-3.5 border-[2px] border-primary-light rounded-[10px] bg-white focus:outline-none"
									aria-label="Volume"
								/>
							</Slider.Root>
							<div className="flex items-center justify-between">
								<p className="text-[#3C3C4399] font-medium text-xs">CHF 25</p>
								<p className="text-[#3C3C4399] font-medium text-xs">CHF 3,000</p>
							</div>
						</div>
						<div className="flex items-center justify-end gap-4">
							<button onClick={handleReset} className="text-primary font-semibold p-0">
								{t(tKey('labels.clear'))}
							</button>
							<button
								onClick={handleFilter}
								className="bg-primary-light py-2.5 px-6 rounded-full font-semibold text-white">
								{t(tKey('labels.apply'))}
							</button>
						</div>
					</div>
				</Modal>
			)}

			<div className="h-full grid grid-cols-1 lg:grid-cols-2 gap-6">
				{isLoading ? (
					<div className="bg-[#E9F1F8] pt-3 px-5">
						<div className="flex items-start w-full gap-3">
							<div className="flex max-lg:flex-col items-center w-full gap-2 lg:gap-3.5">
								<div className="relative w-full">
									<div className="relative w-full flex items-center">
										<MagnifyingGlassIcon className="text-primary size-5 absolute ml-3" />
										<input
											value={name}
											onChange={event => setName(event.target.value)}
											onFocus={() => setIsFocus(true)}
											onBlur={() =>
												setTimeout(() => {
													setIsFocus(false)
												}, 400)
											}
											placeholder={t(tKey('placeholders.name'))}
											className="w-full pl-10 py-3 border-0 text-primary text-sm placeholder:text-primary rounded focus:outline-none focus:border-primary-light bg-white"
										/>
									</div>
									{showSuggestions && isFocus && (
										<SearchSuggestion
											selected={name}
											content={suggestions}
											onSelection={(value: string) => setName(value)}
										/>
									)}
								</div>
								<div className="relative w-full flex items-center">
									<MapPinIcon className="text-primary size-5 absolute ml-3" />
									<input
										ref={ref as any}
										value={country}
										autoComplete="new-password"
										placeholder={t(tKey('placeholders.location'))}
										onChange={event => setCountry(event.target.value)}
										className="w-full pl-10 py-3 border-0 text-primary text-sm placeholder:text-primary rounded focus:outline-none focus:border-primary-light bg-white"
									/>
								</div>
								<button
									onClick={handleSearch}
									className="bg-primary-light max-lg:hidden py-2.5 px-3 rounded-xl">
									<MagnifyingGlassIcon className="size-5 text-white" />
								</button>
								<button
									style={{ boxShadow: '0px 4px 24px 0px #DDD6FE40' }}
									onClick={() => setOpenFilterModal(true)}
									className={clsx(
										'border bg-white max-lg:hidden px-2.5 py-2.5 rounded-xl',
										isFilterApplied ? 'border-primary-light' : 'border-[#CFD8DC]'
									)}>
									<FilterIcon
										className={clsx(
											'size-5',
											isFilterApplied ? 'text-primary-light' : 'text-[#7F9AB2]'
										)}
									/>
								</button>
							</div>
						</div>
						<div className="flex fixed top-1/2 left-1/2 lg:left-1/4 -translate-x-1/2 -translate-y-1/2 items-start  gap-2.5">
							<Spinner className="h-6 w-6" />
							<p className="text-primary text-lg">{t('articles.labels.pleaseWait')}</p>
						</div>
					</div>
				) : (
					<div
						ref={searchRef}
						className="bg-[#E9F1F8] overflow-auto h-full pb-28 w-full flex flex-col gap-5 px-5">
						<div className="flex flex-col w-full gap-2 pt-3">
							<div className="flex items-center w-full gap-3">
								<div className="flex max-lg:flex-col items-center w-full gap-2 lg:gap-3.5">
									<div className="relative w-full">
										<div className="relative w-full flex items-center">
											<MagnifyingGlassIcon className="text-primary size-5 absolute ml-3" />
											<input
												value={name}
												onChange={event => setName(event.target.value)}
												onFocus={() => setIsFocus(true)}
												onBlur={() =>
													setTimeout(() => {
														setIsFocus(false)
													}, 400)
												}
												placeholder={t(tKey('placeholders.name'))}
												className="w-full pl-10 py-3 border-0 text-primary text-sm placeholder:text-primary rounded focus:outline-none focus:border-primary-light bg-white"
											/>
										</div>
										{showSuggestions && isFocus && (
											<SearchSuggestion
												selected={name}
												content={suggestions}
												onSelection={(value: string) => setName(value)}
											/>
										)}
									</div>
									<div className="relative w-full flex items-center">
										<MapPinIcon className="text-primary size-5 absolute ml-3" />
										<input
											ref={ref as any}
											value={country}
											autoComplete="new-password"
											placeholder={t(tKey('placeholders.location'))}
											onChange={event => setCountry(event.target.value)}
											className="w-full pl-10 py-3 border-0 text-primary text-sm placeholder:text-primary rounded focus:outline-none focus:border-primary-light bg-white"
										/>
									</div>
									<button
										onClick={handleSearch}
										className="bg-primary-light max-lg:hidden py-2.5 px-3 rounded-xl">
										<MagnifyingGlassIcon className="size-5 text-white" />
									</button>
									<button
										style={{ boxShadow: '0px 4px 24px 0px #DDD6FE40' }}
										onClick={() => setOpenFilterModal(true)}
										className={clsx(
											'border bg-white max-lg:hidden px-2.5 py-2.5 rounded-xl',
											isFilterApplied ? 'border-primary-light' : 'border-[#CFD8DC]'
										)}>
										<FilterIcon
											className={clsx(
												'size-5',
												isFilterApplied ? 'text-primary-light' : 'text-[#7F9AB2]'
											)}
										/>
									</button>
								</div>
							</div>
							<div className="flex justify-between items-center">
								<p className="text-primary text-sm">
									{searchResultMeta?.totalDocs} {t(tKey('labels.resultsFound'))}
								</p>
								<div className="flex items-center gap-3 lg:hidden">
									<button
										style={{ boxShadow: '0px 4px 24px 0px #DDD6FE40' }}
										onClick={() => setOpenFilterModal(true)}
										className={clsx(
											'border bg-white lg:hidden px-2.5 py-2.5 rounded-xl',
											isFilterApplied ? 'border-primary-light' : 'border-[#CFD8DC]'
										)}>
										<FilterIcon
											className={clsx(
												'size-5',
												isFilterApplied ? 'text-primary-light' : 'text-[#7F9AB2]'
											)}
										/>
									</button>
									<button
										onClick={handleSearch}
										className="bg-primary-light lg:hidden py-2.5 px-3 rounded-xl">
										<MagnifyingGlassIcon className="size-5 text-white" />
									</button>
								</div>
							</div>
						</div>

						{searchResults.map(result => (
							<SearchCard
								key={result._id}
								searchResult={result}
								onToggle={() => setForceRefresh(prev => !prev)}
								isFavourite={favouriteCompanies?.some(company => result._id === company?._id)}
								setCenterPosition={setCenterPosition}
							/>
						))}

						<div className="table-footer-group max-lg:flex max-lg:justify-center">
							<div>
								<div>
									<div className="flex items-center py-5">
										<div className="flex sm:flex-1 sm:items-center sm:justify-between ">
											<div className="w-full sm:w-auto ml-auto text-right mt-2 sm:mt-0">
												<nav
													className="isolate inline-flex gap-x-2 rounded-md"
													aria-label="Pagination">
													<button
														type="button"
														className={clsx(
															'relative inline-flex items-center rounded p-1 text-sm font-medium text-[#FFFFFF] bg-[#1232584D] focus:z-20',
															!searchResultMeta?.hasPrevPage && 'disabled text-[#C4CDD5] opacity-30'
														)}
														onClick={() => setCurrentPage(prev => prev - 1)}>
														<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
														<span className="sr-only">Previous</span>
													</button>

													{pageArray.map(page => (
														<button
															type="button"
															key={page}
															className={clsx(
																'relative bg-white inline-flex w-8 items-center justify-center rounded border px-1 py-[5px] text-primary text-sm font-bold',
																page === currentPage
																	? 'className="relative z-10 inline-flex items-center border-primary focus:z-20'
																	: 'border-[#DFE3E8]'
															)}
															onClick={() => setCurrentPage(page)}>
															{page}
														</button>
													))}
													{!pageArray.includes(totalPages) && (
														<>
															{currentPage < totalPages - 3 ? <div>...</div> : ''}
															<button
																type="button"
																key={'last'}
																className={clsx(
																	'relative inline-flex w-8 items-center justify-center rounded border px-1 py-[5px] text-primary text-sm bg-white font-bold',
																	totalPages === currentPage
																		? 'className="relative z-10 inline-flex items-center border-primary focus:z-20'
																		: 'border-[#DFE3E8]'
																)}
																onClick={() => setCurrentPage(totalPages)}>
																{totalPages}
															</button>
														</>
													)}

													<button
														type="button"
														className={clsx(
															'relative inline-flex items-center rounded p-1 text-sm font-medium text-[#FFFFFF] bg-[#1232584D] focus:z-20',
															!searchResultMeta?.hasNextPage && 'disabled text-[#C4CDD5] opacity-30'
														)}
														onClick={() => setCurrentPage(prev => prev + 1)}>
														<span className="sr-only">Next</span>
														<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
													</button>
												</nav>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{!isMobile && (
					<Map
						currentLocation={currentLocation}
						height="100%"
						searchResults={searchResults}
						centerPosition={centerPosition}
					/>
				)}
			</div>
		</AppLayout>
	)
}

interface SearchCardProps {
	searchResult: SearchResult
	isFavourite: boolean
	onToggle: () => void
	setCenterPosition: (value: { lat: number; lng: number }) => void
}

const SearchCard = ({
	searchResult,
	onToggle,
	isFavourite,
	setCenterPosition
}: SearchCardProps) => {
	const navigate = useNavigate()
	const auth = useAppSelector(state => state.auth)
	const { t } = useTranslation()

	const tKey = getTKey('search')
	const appLanguage = getAppLang()

	const toggleFavourite = () => {
		userService
			.toggleFavourite(searchResult._id)
			.then(res => {
				if (res.message === 'Company added to favorites') {
					toast.success(t(tKey('toasts.favouriteSuccess')))
				} else {
					toast.success(t(tKey('toasts.favouriteRemove')))
				}
				onToggle()
			})
			.catch(err => toast.error(err?.response?.data?.message ?? t(tKey('toasts.favouriteError'))))
	}

	const mappedSchedule = useMemo(() => {
		let mappedScheduleByDays = [] as { [day: number]: Schedule }
		for (const schedule of searchResult.schedules ?? []) {
			mappedScheduleByDays[schedule.day] = schedule
		}
		return mappedScheduleByDays
	}, [searchResult])

	const highestDiscountService = searchResult.services
		.filter(service => service.is_discount_active)
		.sort((a, b) => b.discount - a.discount)[0]

	const highestDiscountValue = highestDiscountService ? highestDiscountService.discount : null

	const todayIndex = DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday
	const todaySchedule = mappedSchedule[todayIndex]

	const isCompanyOpen =
		todaySchedule?.type !== 'Closed' &&
		(todaySchedule?.type === '24' ||
			(todaySchedule &&
				isOpen(DateTime.fromObject(todaySchedule.start), DateTime.fromObject(todaySchedule.end))))

	const isRecentCompany = DateTime.fromMillis(searchResult.created).diffNow('months').months >= -1

	return (
		<div
			onClick={() => navigate(`/company-details/${searchResult._id}`)}
			className="bg-white cursor-pointer rounded-[22px] p-3.5 pr-0 pt-0 flex items-start gap-[18px]">
			{searchResult.gallery.length > 0 ? (
				<div className="grid max-lg:hidden pt-3.5 grid-cols-2 shrink-0 grid-rows-2 gap-2.5">
					{searchResult.gallery.slice(0, 4).map((gallery, index) => (
						<div key={index} className="relative overflow-hidden">
							<img
								src={gallery}
								className={clsx('size-[70px] object-cover', {
									'rounded-tl-lg': index === 0,
									'rounded-tr-lg': index === 1,
									'rounded-bl-lg': index === 2,
									'rounded-br-lg': index === 3
								})}
							/>
							{index === 0 && auth.accessToken && (
								<div
									onClick={event => {
										event.stopPropagation()
										toggleFavourite()
									}}
									className="bg-white absolute top-1 left-1 z-10 cursor-pointer border border-[#CFD8DC] rounded-full p-1">
									<HeartIcon
										className={clsx(
											'size-[18px] hover:fill-pink-400 hover:text-pink-400',
											isFavourite ? 'fill-pink-400 text-pink-400' : 'text-primary-light'
										)}
									/>
								</div>
							)}
						</div>
					))}
				</div>
			) : (
				<div className="grid max-lg:hidden pt-3.5 grid-cols-2 shrink-0 grid-rows-2 gap-2.5">
					<div className="relative">
						<img src={Category1} className="size-[70px] rounded-tl-lg object-cover" />

						{auth.accessToken && (
							<div
								onClick={event => {
									event.stopPropagation()
									toggleFavourite()
								}}
								className="bg-white absolute top-1 left-1 z-10 cursor-pointer border border-[#CFD8DC] rounded-full p-1">
								<HeartIcon
									className={clsx(
										'size-[18px] hover:fill-pink-400 hover:text-pink-400',
										isFavourite ? 'fill-pink-400 text-pink-400' : 'text-primary-light'
									)}
								/>
							</div>
						)}
					</div>
					<div className="relative">
						<img src={Category2} className="size-[70px] rounded-tr-lg object-cover" />
					</div>
					<div className="relative overflow-hidden">
						<img src={Article2} className="size-[70px] rounded-bl-lg object-cover" />
					</div>
					<img src={Category3} className="size-[70px] rounded-br-lg object-cover" />
				</div>
			)}
			<div className="flex flex-col pt-3.5 pr-3.5 relative overflow-hidden w-full gap-4">
				{isRecentCompany && (
					<div className="absolute top-0 right-0 w-[90px] translate-y-3 translate-x-5 h-[20px] bg-red-500 rotate-[45deg] flex items-center justify-center">
						<span className="text-white font-bold text-xs">{t('home.labels.new')}</span>
					</div>
				)}
				<div className="flex items-start gap-3">
					<img src={searchResult.logo_url} alt="logo" className="rounded-lg size-12 object-cover" />
					<div className="flex flex-col gap-[7px]">
						<div className="flex flex-col gap-[7px]">
							<div className="flex items-center gap-4">
								<h1 className="text-primary font-domine max-md:w-[90%] font-bold">
									{searchResult.company_name}
								</h1>
								{highestDiscountValue && (
									<div className="bg-primary-light rounded-full py-0.5 px-1.5">
										<p className="font-bold text-white text-xs whitespace-nowrap">
											{highestDiscountValue}% {t(tKey('labels.off'))}
										</p>
									</div>
								)}
							</div>
							<p
								className="text-primary/80 text-sm line-clamp-2"
								dangerouslySetInnerHTML={{ __html: searchResult.about_company }}
							/>
						</div>
						<div className="flex items-center gap-4 md:gap-6">
							<div className="flex items-center gap-1.5">
								<div className="flex items-center">
									{Array.from({ length: 5 }, (_, index) => {
										const wholeStars = Math.floor(searchResult?.average_rating)
										const isHalfStar =
											index === wholeStars &&
											searchResult?.average_rating % 1 >= 0.3 &&
											searchResult?.average_rating % 1 < 0.8
										return (
											<React.Fragment key={index}>
												{index < wholeStars ? (
													<FaStar className="size-3 md:size-4 text-[#FFB400]" />
												) : isHalfStar ? (
													<FaStarHalfAlt className="size-3 md:size-4 text-[#FFB400]" />
												) : (
													<FaRegStar className="size-3 md:size-4 text-[#FFB400]" />
												)}
											</React.Fragment>
										)
									})}
								</div>
								<p className="text-primary text-sm">
									{Number(searchResult?.average_rating).toFixed(1)}
								</p>
							</div>
							<div className="flex items-center gap-1 md:gap-1.5">
								<ClockIcon className="size-4 shrink-0 text-primary-light" />
								{isCompanyOpen && (
									<p className="text-primary max-md:hidden">
										{DateTime.now().setLocale(appLanguage).weekdayLong}
									</p>
								)}
								{isCompanyOpen && (
									<p className="text-primary md:hidden max-md:text-sm">
										{DateTime.now().setLocale(appLanguage).weekdayShort}
									</p>
								)}
								<p
									className={clsx(
										'max-md:text-sm whitespace-nowrap',
										!isCompanyOpen ? 'text-[#EF3364]' : 'text-[#4CAF50] '
									)}>
									{!isCompanyOpen ? (
										t(tKey('labels.closed'))
									) : mappedSchedule[DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday]
											.type === '24' ? (
										t(tKey('labels.opened'))
									) : (
										<>
											{(() => {
												const dayIndex = DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday
												const schedule = mappedSchedule[dayIndex]

												const start = DateTime.fromObject(schedule?.start).toFormat('HH:mm')
												const pauseStart = DateTime.fromObject(schedule?.pause_start).toFormat(
													'HH:mm'
												)
												const pauseEnd = DateTime.fromObject(schedule?.pause_end).toFormat('HH:mm')
												const end = DateTime.fromObject(schedule?.end).toFormat('HH:mm')

												const hasPause = !DateTime.fromObject(schedule?.pause_start).equals(
													DateTime.fromObject(schedule?.pause_end)
												)

												return hasPause
													? `${start}-${pauseStart} | ${pauseEnd}-${end}`
													: `${start}-${end}`
											})()}
										</>
									)}
								</p>
							</div>
							{searchResult.latitude && searchResult.longitude && (
								<div
									onClick={event => {
										event.stopPropagation()
										setCenterPosition({
											lat: searchResult.latitude as number,
											lng: searchResult.longitude as number
										})
									}}
									className="flex max-lg:hidden hover:underline cursor-pointer items-center gap-1.5">
									<MapPinIcon className="size-4 text-primary-light" />
									<p className="text-primary-light text-sm">{t(tKey('labels.locateOnMap'))}</p>
								</div>
							)}
						</div>
						{searchResult.latitude && searchResult.longitude && (
							<div
								onClick={event => {
									event.stopPropagation()
									setCenterPosition({
										lat: searchResult.latitude as number,
										lng: searchResult.longitude as number
									})
								}}
								className="flex lg:hidden hover:underline cursor-pointer items-center gap-1.5">
								<MapPinIcon className="size-4 text-primary-light" />
								<p className="text-primary-light text-sm">{t(tKey('labels.locateOnMap'))}</p>
							</div>
						)}
					</div>
				</div>
				<div className="w-full h-px border-t border-dashed border-[#CFD8DC]" />
				<div className="flex items-center justify-between">
					<div className="flex items-center w-full gap-2">
						{searchResult.services.slice(0, 2).map((service, index) => (
							<div key={index} className="bg-[#5964D31F] py-1 px-2 rounded-full">
								<p className="text-primary text-xs max-md:w-14 whitespace-nowrap overflow-hidden text-ellipsis">
									{service.name}
								</p>
							</div>
						))}
						{searchResult.services.length > 2 && (
							<div className="rounded-full p-1.5 bg-[#E9F1F8]">
								<p className="text-xs text-primary">+{searchResult.services.length - 2}</p>
							</div>
						)}
					</div>
					<button className="border whitespace-nowrap border-primary-light rounded-full py-1 px-3 text-primary-light text-sm">
						{t(tKey('labels.allServices'))}
					</button>
				</div>
				{searchResult.gallery.length > 0 ? (
					<div className="grid lg:hidden grid-cols-4 shrink-0 gap-2.5">
						{searchResult.gallery.slice(0, 4).map((gallery, index) => (
							<div key={index} className="relative">
								<img
									src={gallery}
									className={clsx('size-[70px] w-full object-cover', {
										'rounded-tl-lg': index === 0,
										'rounded-tr-lg': index === 1,
										'rounded-bl-lg': index === 2,
										'rounded-br-lg': index === 3
									})}
								/>
								{auth.accessToken && index === 0 && (
									<div
										onClick={event => {
											event.stopPropagation()
											toggleFavourite()
										}}
										className="bg-white absolute top-1 left-1 z-10 cursor-pointer border border-[#CFD8DC] rounded-full p-1">
										<HeartIcon
											className={clsx(
												'size-[18px] hover:fill-pink-400 hover:text-pink-400',
												isFavourite ? 'fill-pink-400 text-pink-400' : 'text-primary-light'
											)}
										/>
									</div>
								)}
							</div>
						))}
					</div>
				) : (
					<div className="grid lg:hidden grid-cols-4 shrink-0 gap-2.5">
						<div className="relative">
							<img src={Category1} className="size-[70px] w-full rounded-tl-lg object-cover" />

							{auth.accessToken && (
								<div
									onClick={event => {
										event.stopPropagation()
										toggleFavourite()
									}}
									className="bg-white absolute top-1 left-1 z-10 cursor-pointer border border-[#CFD8DC] rounded-full p-1">
									<HeartIcon
										className={clsx(
											'size-[18px] hover:fill-pink-400 hover:text-pink-400',
											isFavourite ? 'fill-pink-400 text-pink-400' : 'text-primary-light'
										)}
									/>
								</div>
							)}
						</div>
						<div className="relative">
							<img src={Category2} className="size-[70px] w-full rounded-tr-lg object-cover" />
						</div>
						<img src={Article2} className="size-[70px] w-full rounded-bl-lg object-cover" />
						<img src={Category3} className="size-[70px] w-full rounded-br-lg object-cover" />
					</div>
				)}
			</div>
		</div>
	)
}
