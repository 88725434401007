import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import * as yup from 'yup'

import { AppointoGreenIcon, CheckmarkIcon, DeleteIcon, StylingCornerColorIcon } from 'assets/icons'
import { ReactComponent as PoweredByIcon } from 'assets/svgs/powered-by.svg'
import { LanguageMenu } from 'components/app/language-menu'
import { AppLayout } from 'components/app/layout'
import { Modal } from 'components/app/modal'
import { ForgotPassword } from 'components/auth/forgot-password'
import { Login } from 'components/auth/login'
import { Otp } from 'components/auth/otp'
import { Register } from 'components/auth/register'
import { ResetPassword } from 'components/auth/reset-password'
import { AuthOptions } from 'constants/constants'
import { useAppDispatch, useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import appointmentService from 'services/appointment-service'
import authService from 'services/auth-service'
import { login } from 'slices/auth'
import { getAppLang, getTKey } from 'utils/language'
import addJobToFirebase from '../../firebase/utility'

export const Auth = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { i18n, t } = useTranslation()
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const isMobile = useIsMobile()

	const tKey = getTKey('auth')
	const appLanguage = getAppLang()

	const auth = useAppSelector(state => state.auth)
	const [pin, setPin] = useState('')
	const [forgotPasswordPin, setForgotPasswordPin] = useState('')
	const [isEmailSent, setIsEmailSent] = useState(false)
	const [showModal, setShowModal] = useState({
		success: false,
		error: false
	})
	const [isLoading, setIsLoading] = useState({
		login: false,
		register: false,
		forgotPassword: false,
		forgotPasswordOtp: false,
		resetPassword: false,
		otp: false
	})
	const [loginFlow, setLoginFlow] = useState(true)
	const [isPhoneVerification, setIsPhoneVerification] = useState(false)
	const [showOtp, setShowOtp] = useState(false)
	const [formData, setFormData] = useState({} as Auth)

	const currentTab = searchParams.get('tab') ?? AuthOptions.LOGIN
	const appointmentId = searchParams.get('appointmentId')

	const schema = yup.object<Auth>().shape({
		username: yup.string().when('$step', {
			is: AuthOptions.LOGIN,
			then: schema => schema.required(t(tKey('errors.email')))
		}),
		fname: yup.string().when('$step', {
			is: AuthOptions.REGISTER,
			then: schema => schema.required(t(tKey('errors.firstname')))
		}),
		lname: yup.string().when('$step', {
			is: AuthOptions.REGISTER,
			then: schema => schema.required(t(tKey('errors.lastname')))
		}),
		password: yup.string().when('$step', {
			is: (step: AuthOptions) => step !== AuthOptions.OTP && step !== AuthOptions.FORGOT,
			then: schema =>
				schema
					.required(t(tKey('errors.registerPassword')))
					.min(8, t(tKey('errors.minPassword')))
					.test('password-test', t(tKey('errors.validPassword')), function (value) {
						if (currentTab === AuthOptions.REGISTER || currentTab === AuthOptions.RESET) {
							if (!/^(?=.*[A-Z])(?=.*[0-9!@#\$%\^\&*\)\(+=._-]).{8,}$/.test(value)) {
								return false
							}
						}
						return true
					})
		}),
		confirmPassword: yup.string().when('$step', {
			is: (step: AuthOptions) => step === AuthOptions.REGISTER || step === AuthOptions.RESET,
			then: schema =>
				schema
					.required(t(tKey('errors.confirmPassword')))
					.oneOf([yup.ref('password'), ''], t(tKey('errors.matchPassword')))
		}),
		phonenumber: yup.string().when('$step', {
			is: (step: AuthOptions) =>
				step === AuthOptions.REGISTER || (step === AuthOptions.FORGOT && isPhoneVerification),
			then: schema =>
				schema.required(t(tKey('errors.phonenumber'))).max(13, t(tKey('errors.maxPhone')))
		}),
		email: yup.string().when('$step', {
			is: (step: AuthOptions) =>
				step === AuthOptions.REGISTER || (step === AuthOptions.FORGOT && !isPhoneVerification),
			then: schema => schema.required(t(tKey('errors.email'))).email(t(tKey('errors.validEmail')))
		}),
		gender: yup.string().when('$step', {
			is: AuthOptions.REGISTER,
			then: schema => schema.required(t(tKey('errors.gender')))
		}),
		agreement: yup.string().when('$step', {
			is: (step: AuthOptions) => step === AuthOptions.REGISTER || step === AuthOptions.LOGIN,
			then: schema => schema.required(t(tKey('errors.termsAgree')))
		})
	})

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		control,
		formState: { errors }
	} = useForm<Auth>({
		resolver: yupResolver(schema as any),
		context: { step: currentTab },
		defaultValues: {
			username: undefined,
			fname: undefined,
			lname: undefined,
			password: undefined,
			confirmPassword: undefined,
			email: undefined,
			phonenumber: undefined
		},
		mode: 'all'
	})

	useEffect(() => {
		if (currentTab === AuthOptions.REGISTER) {
			setLoginFlow(false)
			reset()
		} else if (currentTab === AuthOptions.LOGIN) {
			setLoginFlow(true)
			reset()
		}
	}, [currentTab])

	useEffect(() => {
		if (auth.accessToken && location.pathname.includes('cancel')) {
			cancelAppointment()
		}
		if (location.pathname.includes('cancel')) {
			navigate(`/cancel?tab=${AuthOptions.LOGIN}&appointmentId=${appointmentId}`)
		}
	}, [auth])

	const cancelAppointment = () => {
		appointmentService
			.cancelAppointment(appointmentId as string, { status: 'canceled' })
			.then(res => {
				addJobToFirebase(res.data)
				setShowModal({ success: true, error: false })
			})
			.catch(error => {
				setShowModal({ success: false, error: error?.response?.data?.message })
			})
	}

	const onFormSubmit = (data: Auth) => {
		if (currentTab === AuthOptions.REGISTER) {
			setIsLoading(prev => ({ ...prev, register: true }))
			setFormData(data)
			authService
				.sendMobileVerification(data.phonenumber, true, undefined, data.email)
				.then(() => {
					toast.success(t(tKey('toast.verificationSent')))
					if (location.pathname.includes('cancel')) {
						return navigate(`/cancel?tab=${AuthOptions.OTP}&appointmentId=${appointmentId}`)
					}
					setSearchParams({ tab: AuthOptions.OTP }, { replace: true })
				})
				.catch(err => {
					if (err.response && err.response.data) {
						return toast.error(err.response.data.message)
					}
					toast.error(t(tKey('toast.error')))
				})
				.finally(() => setIsLoading(prev => ({ ...prev, register: false })))
		}
		if (currentTab === AuthOptions.FORGOT) {
			setFormData(data)
			if (showOtp) {
				if (!forgotPasswordPin || forgotPasswordPin.length !== 6) {
					return toast.error(t(tKey('toast.validPin')))
				}
				setIsLoading(prev => ({ ...prev, forgotPasswordOtp: true }))
				authService
					.forgotPasswordMobileVerification(formData.phonenumber, forgotPasswordPin)
					.then(res => {
						toast.success(t(tKey('toast.verificationSuccess')))
						navigate(`/auth?tab=${AuthOptions.RESET}&code=${res.otp}&phone=${formData.phonenumber}`)
					})
					.catch(err => {
						if (err.response && err.response.data) {
							return toast.error(err.response.data.message)
						}
						toast.error(t(tKey('toast.validationError')))
					})
					.finally(() => setIsLoading(prev => ({ ...prev, forgotPasswordOtp: false })))
			} else {
				setIsLoading(prev => ({ ...prev, forgotPassword: true }))
				authService
					.forgotPassword(
						isPhoneVerification ? undefined : data.email,
						isPhoneVerification ? data.phonenumber : undefined
					)
					.then(() => {
						if (isPhoneVerification) {
							setShowOtp(true)
						} else {
							setIsEmailSent(true)
						}
					})
					.catch(err => {
						if (err.response && err.response.data) {
							return toast.error(err.response.data.message)
						}
						toast.error(t(tKey('toast.error')))
					})
					.finally(() => setIsLoading(prev => ({ ...prev, forgotPassword: false })))
			}
		}
		if (currentTab === AuthOptions.RESET) {
			setIsLoading(prev => ({ ...prev, resetPassword: true }))
			authService
				.resetPassword(
					data.password,
					searchParams.get('code') as string,
					searchParams.get('email') as string,
					`+${(searchParams.get('phone') as string)?.trim()}`
				)
				.then(() => {
					toast.success(t(tKey('toast.passwordReset')))
					setSearchParams({
						tab: AuthOptions.LOGIN
					})
				})
				.catch(err => {
					if (err.response && err.response.data) {
						return toast.error(err.response.data.message)
					}
					toast.error(t(tKey('toast.error')))
				})
				.finally(() => setIsLoading(prev => ({ ...prev, resetPassword: false })))
		}
		if (currentTab === AuthOptions.OTP) {
			if (!pin || pin.length !== 6) {
				return toast.error(t(tKey('toast.validPin')))
			}
			setIsLoading(prev => ({ ...prev, otp: true }))
			authService
				.mobileVerification(formData.phonenumber || formData.username, pin)
				.then(() => {
					toast.success(t(tKey('toast.verificationSuccess')))
					if (loginFlow) {
						dispatch(login({ username: formData.username, password: formData.password }))
							.then(() => {
								if (location.pathname.includes('cancel')) {
									return window.location.reload()
								}
							})
							.catch(() => toast.error(t(tKey('toast.invalidCredentials'))))
							.finally(() => setIsLoading(prev => ({ ...prev, login: false })))
					} else if (!loginFlow) {
						authService
							.register({
								...formData,
								phone: formData.phonenumber
							})
							.then(() => {
								toast.success(t(tKey('toast.registrationSuccess')))
								authService.sendRegistrationEmail({
									name: formData.fname,
									phone: formData.phonenumber,
									email: formData.email,
									password: formData.password
								})
								dispatch(login({ username: formData.phonenumber, password: formData.password }))
									.then(() => {
										toast.success(t('auth.toast.loginSuccess'))
									})
									.catch(() => toast.error(t(tKey('toast.invalidCredentials'))))
									.finally(() => setIsLoading(prev => ({ ...prev, login: false })))
							})
							.catch(err => {
								if (err.response && err.response.data) {
									return toast.error(err.response.data.message)
								}
								toast.error(t(tKey('toast.registrationError')))
							})
					}
				})
				.catch(err => {
					if (err.response && err.response.data) {
						return toast.error(err.response.data.message)
					}
					toast.error(t(tKey('toast.validationError')))
				})
				.finally(() => setIsLoading(prev => ({ ...prev, otp: false })))
		}
		if (currentTab === AuthOptions.LOGIN) {
			setIsLoading(prev => ({ ...prev, login: true }))
			setFormData(data)
			authService
				.sendMobileVerification(data.username, false, data.password)
				.then(() => {
					toast.success(t(tKey('toast.verificationSuccess')))
					if (location.pathname.includes('cancel')) {
						return navigate(`/cancel?tab=${AuthOptions.OTP}&appointmentId=${appointmentId}`)
					}
					setSearchParams({ tab: AuthOptions.OTP }, { replace: true })
				})
				.catch(err => {
					if (err.response && err.response.data) {
						return toast.error(err.response.data.message)
					}
					toast.error(t(tKey('toast.error')))
				})
				.finally(() => setIsLoading(prev => ({ ...prev, login: false })))
		}
	}

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
	}

	const onResendClick = () => {
		authService
			.forgotPassword(formData.email)
			.then(() => {
				toast.success(t(tKey('toast.emailSent')))
				setIsEmailSent(true)
			})
			.catch(err => {
				if (err.response && err.response.data) {
					return toast.error(err.response.data.message)
				}
				toast.error(t(tKey('toast.error')))
			})
	}
	const sendOtp = () => {
		authService
			.sendMobileVerification(
				formData.phonenumber || formData.username,
				!loginFlow,
				formData.password,
				formData.email
			)
			.then(() => {
				toast.success(t(tKey('toast.verificationSent')))
				if (location.pathname.includes('cancel')) {
					return navigate(`/cancel?tab=${AuthOptions.OTP}&appointmentId=${appointmentId}`)
				}
				setSearchParams({ tab: AuthOptions.OTP }, { replace: true })
			})
			.catch(err => {
				if (err.response && err.response.data) {
					return toast.error(err.response.data.message)
				}
				toast.error(t(tKey('toast.error')))
			})
	}

	if (showModal.success) {
		return (
			<Modal onClose={() => navigate('/user/appointments')} showCrossIcon={true} isFullHeight>
				<AppLayout renderDashboardHeader={isMobile}>
					<div className="flex flex-col py-[100px] px-[24px]">
						<img src={CheckmarkIcon} alt="Checkmark" className=" mx-auto mb-10 object-contain" />

						<h1 className="text-primary font-domine text-2xl font-bold leading-8 capitalize text-center mb-3.5">
							{t(tKey('headings.cancelled'))}
						</h1>
						<p className="text-secondary leading-6 text-base font-nunito text-center font-normal">
							{t(tKey('headings.details'))}
						</p>
					</div>
				</AppLayout>
			</Modal>
		)
	}

	if (showModal.error) {
		return (
			<Modal onClose={() => navigate('/user/appointments')} showCrossIcon={true} isFullHeight>
				<AppLayout renderDashboardHeader={isMobile}>
					<div className="flex flex-col py-[100px] px-[24px]">
						<img
							src={DeleteIcon}
							alt="Checkmark"
							className=" mx-auto h-14 w-14 mb-10 object-contain"
						/>

						<h1 className="text-primary font-domine text-2xl font-bold leading-8 capitalize text-center mb-3.5">
							{t(tKey('headings.cancelledError'))}
						</h1>
						<p className="text-secondary leading-6 text-base font-nunito text-center font-normal">
							{showModal.error ?? t(tKey('headings.detailsError'))}
						</p>
					</div>
				</AppLayout>
			</Modal>
		)
	}

	return (
		<>
			{auth.accessToken ? (
				<></>
			) : (
				<>
					<div
						className="flex flex-col gap-y-2 min-h-screen items-center max-lg:hidden bg-rectangle bg-no-repeat bg-cover"
						style={{ backgroundPositionY: '-250px' }}>
						<div className="flex self-end mt-10 mr-10">
							<LanguageMenu
								value={i18n.language || window.localStorage.i18nextLng}
								onChange={changeLanguage}
								options={['en', 'de']}
								className="!bg-primary-light"
							/>
						</div>
						<div className="flex flex-col mb-6 items-center">
							<img src={StylingCornerColorIcon} className="w-52" />
						</div>

						{!isMobile && (
							<form onSubmit={handleSubmit(onFormSubmit)}>
								<div className="relative rounded-xl bg-white p-14 w-[50rem] container-shadow">
									{currentTab === AuthOptions.REGISTER && (
										<Register
											register={register}
											errors={errors}
											control={control}
											watch={watch}
											isLoading={isLoading.register}
										/>
									)}
									{currentTab === AuthOptions.LOGIN && (
										<Login
											register={register}
											errors={errors}
											setValue={setValue}
											watch={watch}
											control={control}
											isLoading={isLoading.login}
										/>
									)}
									{currentTab === AuthOptions.FORGOT && (
										<ForgotPassword
											email={formData.email}
											isEmailSent={isEmailSent}
											pin={forgotPasswordPin}
											setPin={setForgotPasswordPin}
											control={control}
											setValue={setValue}
											isOtpLoading={isLoading.forgotPasswordOtp}
											watch={watch}
											isPhoneVerification={isPhoneVerification}
											setIsPhoneVerification={setIsPhoneVerification}
											setIsEmailSent={setIsEmailSent}
											register={register}
											onResendClick={onResendClick}
											errors={errors}
											showOtp={showOtp}
											isLoading={isLoading.forgotPassword}
										/>
									)}
									{currentTab === AuthOptions.RESET && (
										<ResetPassword
											register={register}
											errors={errors}
											isLoading={isLoading.resetPassword}
										/>
									)}
									{currentTab === AuthOptions.OTP && (
										<Otp
											pin={pin}
											onChange={(value: string) => setPin(value)}
											onResendOtp={sendOtp}
											phoneNumber={
												loginFlow
													? !formData.username?.includes('@')
														? formData.username
														: ''
													: formData.phonenumber
											}
											isLoading={isLoading.otp}
										/>
									)}
								</div>
							</form>
						)}
						<PoweredByIcon
							onClick={() => window.open('https://resolut-technologies.ch/', '_blank')}
							className="ml-auto cursor-pointer max-lg:hidden mt-auto relative max-md:h-12 max-md:w-48 bottom-0 translate-y-4 mr-4 md:mb-7 md:mr-8"
						/>
					</div>
					<div className="lg:hidden">
						<AppLayout renderHeader>
							<div className="relative flex flex-col items-center gap-y-6 h-screen">
								<div className="relative flex flex-col items-center gap-y-2.5 w-full h-80 pt-11 bg-blue-gradient">
									<img src={AppointoGreenIcon} className="w-52" />
									<h3 className="uppercase text-[22px] text-[#FFF]">Styling Corner</h3>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="375"
										height="163"
										viewBox="0 0 375 163"
										className="absolute"
										fill="none">
										<path
											d="M380.172 162.016C346.268 84.5909 251.205 -63.6521 200.641 32.8957C166.502 98.0825 247.747 101.956 236.065 40.6631C221.269 -36.9723 57.7501 41.9807 0.339258 71.0711"
											stroke="white"
											strokeOpacity="0.11"
											strokeDasharray="5 5"
										/>
									</svg>
								</div>

								{isMobile && (
									<form
										className="bg-white absolute flex flex-col justify-between overflow-y-auto overflow-x-hidden translate-y-40 rounded-xl py-8 w-11/12"
										onSubmit={handleSubmit(onFormSubmit)}>
										{currentTab === AuthOptions.REGISTER && (
											<Register
												register={register}
												errors={errors}
												control={control}
												watch={watch}
												isLoading={isLoading.register}
											/>
										)}
										{currentTab === AuthOptions.LOGIN && (
											<Login
												setValue={setValue}
												watch={watch}
												control={control}
												register={register}
												errors={errors}
												isLoading={isLoading.login}
											/>
										)}
										{currentTab === AuthOptions.FORGOT && (
											<ForgotPassword
												email={formData.email}
												isEmailSent={isEmailSent}
												setValue={setValue}
												control={control}
												isOtpLoading={isLoading.forgotPasswordOtp}
												pin={forgotPasswordPin}
												setPin={setForgotPasswordPin}
												watch={watch}
												showOtp={showOtp}
												isPhoneVerification={isPhoneVerification}
												setIsPhoneVerification={setIsPhoneVerification}
												setIsEmailSent={setIsEmailSent}
												register={register}
												onResendClick={onResendClick}
												errors={errors}
												isLoading={isLoading.forgotPassword}
											/>
										)}
										{currentTab === AuthOptions.RESET && (
											<ResetPassword
												register={register}
												errors={errors}
												isLoading={isLoading.resetPassword}
											/>
										)}
										{currentTab === AuthOptions.OTP && (
											<Otp
												pin={pin}
												onChange={(value: string) => setPin(value)}
												onResendOtp={sendOtp}
												phoneNumber={formData.phonenumber}
												isLoading={isLoading.otp}
											/>
										)}
										<PoweredByIcon
											onClick={() => window.open('https://resolut-technologies.ch/', '_blank')}
											className="ml-auto cursor-pointer mt-auto max-md:h-12 relative max-md:w-48 bottom-0 translate-y-4 mr-4"
										/>
									</form>
								)}
							</div>
						</AppLayout>
					</div>
				</>
			)}
		</>
	)
}
