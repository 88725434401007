import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
	AppleAppStore,
	ArticleLeftCircle,
	ArticleTopCircle,
	CouponImage,
	CouponPromotion,
	GooglePlayStore,
	MobileCouponPromotion,
	SmallAppStoreIcon,
	SmallPlayStoreIcon
} from 'assets/icons'
import { ReactComponent as WorkOneIcon } from 'assets/svgs/work-one.svg'
import { ReactComponent as WorkThreeIcon } from 'assets/svgs/work-three.svg'
import { ReactComponent as WorkTwoIcon } from 'assets/svgs/work-two.svg'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { FAQ } from 'components/home/faq'
import { SubscriptionBox } from 'components/home/subscription-box'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { getTKey } from 'utils/language'

export const Coupon = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const isMobile = useIsMobile()

	const tKey = getTKey('coupon')

	const auth = useAppSelector(state => state.auth)

	return (
		<AppLayout renderHomeHeader={!auth.accessToken} renderDashboardHeader={!!auth.accessToken}>
			<div className="bg-mobileCouponBackground md:bg-couponBackground max-md:h-[600px] py-10 px-3 md:py-[100px] md:px-[120px] bg-cover bg-center bg-no-repeat flex">
				<div className="flex flex-col max-md:items-center gap-2 md:gap-8 md:basis-2/5">
					<h1 className="text-primary max-md:text-center font-domine font-bold text-[32px] md:text-[56px]">
						{t(tKey('titles.welcome'))}
					</h1>
					<p className="text-primary max-md:text-center max-md:text-sm">
						{t(tKey('labels.sayHello'))}
					</p>
					<button
						onClick={() => navigate('/user/coupons')}
						className="bg-[#5964D3] max-md:mt-3 w-fit whitespace-nowrap text-white py-2.5 md:py-3 px-6 rounded-lg hover:bg-[#7D8AE6] focus:outline-none shadow-lg">
						{t(tKey('buttons.getStarted'))}
					</button>
				</div>
			</div>

			<div className="md:pt-20 pt-14 px-6 md:px-[120px] flex flex-col gap-6 md:gap-[51px] items-center">
				<div className="flex max-md:flex-col max-md:gap-6 self-stretch items-center md:justify-between">
					<h1 className="text-primary md:basis-3/5 font-domine font-bold text-3xl md:text-5xl">
						{t(tKey('titles.howItWorks'))}
					</h1>
					<p className="text-primary md:text-2xl md:basis-2/5">{t(tKey('labels.everyBooking'))}</p>
				</div>
				<div className="max-md:flex max-md:flex-col-reverse md:grid md:grid-cols-2 gap-8 md:gap-[100px] items-center relative">
					<div className="flex relative flex-col gap-[60px]">
						<div className="absolute inset-0 -z-10 h-[70%] md:h-[75%] max-md:-translate-y-[65%] translate-x-[26px] top-1/2 -translate-y-1/2 w-1 border-r border-dashed border-[#7F9AB2]" />
						<div className="flex items-start gap-6 md:gap-[37px]">
							<WorkOneIcon className="shrink-0" />
							<div className="flex flex-col gap-5">
								<h2 className="text-primary font-domine font-bold text-xl md:text-2xl">
									{t(tKey('titles.bookTreatments'))}
								</h2>
								<p className="text-primary">{t(tKey('labels.logIn'))}</p>
							</div>
						</div>
						<div className="flex items-start gap-[37px]">
							<WorkTwoIcon className="shrink-0" />
							<div className="flex flex-col gap-5">
								<h2 className="text-primary font-domine font-bold text-xl md:text-2xl">
									{t(tKey('titles.earnPoints'))}
								</h2>
								<p className="text-primary">{t(tKey('labels.payOnline'))}</p>
							</div>
						</div>
						<div className="flex items-start gap-5 md:gap-[37px]">
							<WorkThreeIcon className="shrink-0" />
							<div className="flex flex-col gap-5">
								<h2 className="text-primary font-domine font-bold text-xl md:text-2xl">
									{t(tKey('titles.redeemDiscounts'))}
								</h2>
								<p className="text-primary">{t(tKey('labels.hitReward'))}</p>
							</div>
						</div>
					</div>
					<img src={CouponImage} alt="coupon-info" className="ml-auto" />
				</div>
			</div>

			<div className="relative mt-14 md:mt-[100px] flex flex-col items-center justify-between mx-6 md:mx-[120px] overflow-hidden bg-[#B790D8] max-md:px-6 md:px-12 pt-14 pb-0 rounded-[48px] font-semibold text-xl">
				<div className="flex flex-col items-center gap-4 md:gap-10">
					<h2 className="text-primary font-bold font-domine max-md:text-center text-3xl md:text-[40px]">
						{t(tKey('titles.rewardDashboard'))}
					</h2>
					<p className="text-primary text-base md:text-[18px] text-center">
						{t(tKey('labels.trackPoints'))}
					</p>
					<div className="flex max-md:mt-10 items-center justify-center gap-4 md:gap-6">
						<img
							src={isMobile ? SmallPlayStoreIcon : GooglePlayStore}
							alt="Google Play"
							className="md:w-[231px] max-md:max-w-[130px] w-full h-[50px] md:h-[70px] rounded-md"
						/>
						<img
							src={isMobile ? SmallAppStoreIcon : AppleAppStore}
							alt="App Store"
							className="md:w-[231px] max-md:max-w-[130px] w-full h-[47px] md:h-[67px] rounded-md"
						/>
					</div>
				</div>
				<img src={ArticleLeftCircle} className="absolute top-0 left-0" />
				<img src={ArticleTopCircle} className="absolute bottom-0 right-5" />
				<img src={CouponPromotion} className="mt-4 max-md:hidden" />
				<img src={MobileCouponPromotion} className="md:hidden mt-10" />
			</div>
			<div className="my-14 md:my-[100px]">
				<FAQ />
			</div>
			<div className="mb-14">
				<SubscriptionBox type="coupon" />
			</div>
			<Footer />
		</AppLayout>
	)
}
