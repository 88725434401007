import { AppointmentStatus } from 'constants/constants'
import { DateTime } from 'luxon'

export const isUpcomingAppointment = (date: number, status: string) => {
	return (
		DateTime.fromMillis(Number(date)) > DateTime.now() &&
		status !== AppointmentStatus.CANCELLED &&
		status !== 'canceled'
	)
}

export const isWithinUpdateTime = (date: number, status: string) => {
	const presentDate = DateTime.now()
	return DateTime.fromMillis(date).diff(presentDate, 'hours').hours >= 3
}
